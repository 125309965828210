import React, { Component } from 'react';
import { Row, Col, Form, Input, message } from "antd";
import { LinkedinFilled, EnvironmentOutlined, MailOutlined, PhoneOutlined,  } from "@ant-design/icons";
import {SectionContainer, ContainerFluid} from "../../globalStyle"
import {BannerWrapper, 
        BannerTitle,
        DetailsWrapper,
        DetailLists,
        DetailField,
        FormContainer,
        BehindPrajeckProTitle,
        SubmitBtn} from "./contact.us.style"
import apiService from '../../services/api-service';

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
function ContactUs() {
  
    const [form] = Form.useForm();
    
    const onFinish = async (values) => {
        
        try {

            const apiPayload = {
                contactNo: values.mobile_No,
                emailId: values.user.email,
                enquiryFor: 1,
                firstName: values.first_Name,
                lastName: values.last_Name,
                message: values.user.description,
                source: "WEBSITE"
            }
    
            await apiService.post('/pp/public/addEnquiry', apiPayload);
    
            form.resetFields();
    
            message.success('Thank you for your response, we will contact you later !');

        } catch(err) {
            console.error("onFinish : error ", err);
            message.error('Something went wrong, please refresh the page');
        }

    };
    const Banner = () => {
        return (
            <BannerWrapper>
                <BannerTitle>contact us</BannerTitle>
            </BannerWrapper>
        )
    } 

    const ContactUsForm = () => {
        return (
            <>
                <Form layout="vertical" form={form} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name={['first_Name']}
                                label="First Name"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name={['last_Name']}
                                label="Last Name"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </Col>
                            <Col xs={24} md={12}>
                            <Form.Item
                                name={['mobile_No']}
                                label="Mobile Number"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name={['user', 'email']}
                                label="Email"
                                rules={[
                                {
                                    type: 'email',
                                    required: true
                                },
                                ]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item name={['user', 'description']} label="Description">
                                <Input.TextArea size="large" rows={4}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <SubmitBtn type="primary" htmlType="submit">
                        Submit
                        </SubmitBtn>
                    </Form.Item>
                </Form>
            </>
        )
    }

    const ContactDetails = () => {
        return (
            <DetailsWrapper>
                <DetailLists>
                        <EnvironmentOutlined />
                        <DetailField>
                            Rm. 1202 6776 Ayala Avenue 1200, Makati City, 
                            Metro Manila, Philippines
                        </DetailField>
                </DetailLists>
                <DetailLists>
                        <MailOutlined />
                        <DetailField>
                            <a href="mailto:support@prajekpro.com" style={{display: "inline-block",fontSize:"inherit", color:"inherit"}}> 
                                support@prajekpro.com
                            </a>
                        </DetailField>
                </DetailLists>
                <DetailLists>
                        <PhoneOutlined />
                        <DetailField>
                            +638911320
                        </DetailField>
                </DetailLists>    
            </DetailsWrapper>
        )
    }
    return ( 
        <>
            <Banner/>
            <FormContainer>
                <SectionContainer>
                    <BehindPrajeckProTitle>how to reach us </BehindPrajeckProTitle>
                    <Row gutter={20} style={{justifyContent:"center"}}>
                    <Col xs={24} lg={12}>
                        <ContactUsForm/>
                    </Col>
                    {/* <Col xs={24} md={12}>
                        <ContactDetails/>
                    </Col> */}
                    </Row>
                </SectionContainer>
            </FormContainer>
        </>
    );
}
 
export default ContactUs;