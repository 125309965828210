import React, { Component, Fragment } from "react";
import { Row, Col, Modal } from "antd";
import { SectionContainer } from "../../globalStyle";
import {
  FooterWrapper,
  FooterLogo,
  SectionHeading,
  FooterLink,
  CopyrightWrapper,
  FooterAddress,
  FooterSection,
  PlaystoreButtonContainer,
  PlaystoreButton,
  PropertyText,
} from "./Footer.style";
import googlePlayBadge from "../../images/google-play-badge.png";
import appStoreBadge from "../../images/app-store-badge.png";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  ArrowDownOutlined,
  RedEnvelopeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useState } from "react";

class Footer extends Component {
  state = {};
  render() {
    const PlayStoreButton = () => {
      const [isModalOpen, setIsModalOpen] = useState(false);
      const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      return (
        <Fragment>
          <button
            type="button"
            onClick={showModal}
            className="app-download-button"
          >
            <FooterLogo
              src={googlePlayBadge}
              alt="Google play Store"
              style={{ display: "block", width: "100%" }}
            />
          </button>
          <Modal
            title="Download App"
            centered
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleOk}
            footer={null}
          >
            <PlaystoreButtonContainer>
              <PlaystoreButton href="https://play.google.com/store/apps/details?id=com.prajekpro.custapp">
                <ArrowDownOutlined /> Download Cutsomer App
              </PlaystoreButton>
              <PlaystoreButton href="https://play.google.com/store/apps/details?id=com.prajekpro.profapp">
                <ArrowDownOutlined /> Download Professional App
              </PlaystoreButton>
            </PlaystoreButtonContainer>
          </Modal>
        </Fragment>
      );
    };
    return (
      <Fragment>
        <FooterWrapper>
          <SectionContainer>
            <Row gutter={16}>
              <Col xs={12} sm={6} md={6}>
                <SectionHeading>useful links</SectionHeading>
                <ul>
                  <li>
                    <FooterLink to="/">home</FooterLink>
                  </li>
                  <li>
                    <FooterLink to="/about-us">about us</FooterLink>
                  </li>
                  <li>
                    <FooterLink to="/contact-us">contact us</FooterLink>
                  </li>
                </ul>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <SectionHeading>product help</SectionHeading>
                <ul>
                  <li>
                    <FooterLink to="/faqs">faq's</FooterLink>
                  </li>
                  <li>
                    <FooterLink to="/privacy-policy">privacy policy</FooterLink>
                  </li>
                  <li>
                    <FooterLink to="/data-policy">data policy</FooterLink>
                  </li>
                  <li>
                    <FooterLink to="/terms-conditions">
                      terms & conditions
                    </FooterLink>
                  </li>
                </ul>
              </Col>
              <Col xs={24} sm={6} md={6}>
                <SectionHeading>Location</SectionHeading>
                <FooterSection>
                  <EnvironmentOutlined />
                  <FooterAddress>
                    Ph4 blk13 lot11 Wisteria st. villa de Primarosa buhay na
                    tubig, imus, Cavite 4103
                  </FooterAddress>
                </FooterSection>
                <FooterSection>
                  <PhoneOutlined />
                  <FooterAddress> +63 46-515-1483</FooterAddress>
                </FooterSection>
                <FooterSection>
                  <RedEnvelopeOutlined />
                  <FooterAddress>
                    <a
                      href="mailto:inbox@prajekpro.com"
                      style={{ color: "#fff" }}
                    >
                      inbox@prajekpro.com
                    </a>
                  </FooterAddress>
                </FooterSection>
              </Col>
              <Col xs={24} sm={6} md={6}>
                <SectionHeading>download</SectionHeading>
                <ul>
                  <li>
                    <PlayStoreButton />
                  </li>
                  <li>
                    <Link to="/comming-soon">
                      <FooterLogo src={appStoreBadge} alt="App Store" />
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
            <CopyrightWrapper>
              © Copyrights 2023 Prajekpro All rights reserved.
            </CopyrightWrapper>
            <PropertyText>
              Prajekpro website, Prajekpro mobile application, and Prajekpro
              Professional mobile application are the exclusive intellectual
              property of Navalta-Chavan Technologies Corp.
            </PropertyText>
          </SectionContainer>
        </FooterWrapper>
      </Fragment>
    );
  }
}

export default Footer;
