import styled from "styled-components";
import { Row } from "antd"; 
import { COLORS, SIZES, FONT_WEIGHT, headerHeight } from "../../constants/theme";
import { Link } from "react-router-dom";
export const BgHeroWrapper = styled.div`
    background: ${COLORS.white_color};
    padding-top: 80px;
    padding-bottom: 100px; 
    position: relative;
    @media(max-width: 767px) {
        padding-top: 50px;
        padding-bottom: 50px; 
    }
` 
export const HeroTitle = styled.h1`
    font-size: 64px;
    color: ${COLORS.black_primary};
    font-weight: ${FONT_WEIGHT.bold_lg};
    line-height: 80px;
    margin-bottom: 20px;
    text-transform: capitalize;
    @media(max-width: 767px) {
        margin-top: 30px;
    }
    @media(max-width: 992px) {
        font-size: 36px;
        line-height: 50px; 
    }
`
export const HeroBottomText = styled.p`
    font-size: ${SIZES.sm};
    color: ${COLORS.secondary_color};
    line-height: 26px;
    margin-bottom: 30px;
` 
export const HeroBottomBtn = styled.a`
    display: block;
    border: 1px solid ${COLORS.primary_color};
    border-radius: 6px;
    font-size: ${SIZES.sm};
    color: ${COLORS.white_color};
    background-color: ${COLORS.primary_color};
    font-weight: 600;
    height: 46px;
    line-height: 46px;
    text-transform: uppercase;
    text-align: center;
    width: 160px;
    transition: 0.3s;
    outline: none;
    &:hover{    
        background-color: ${COLORS.white_color};
        color: ${COLORS.primary_color};    
    }
`
export const BannerImg = styled.img`
    width:100%;
    display: block;

    @media (min-width: 768px) {
        max-width:560px;
        margin: 0 auto;
    }
`
export const BannerContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        justify-content: center;
    }
    @media (max-width: 767px) { 
        text-align: center;
        align-items: center;
    }
`
export const ServiceBgWrapper = styled.div`
    background-color: ${COLORS.light_blue};
    padding: 100px 0;
    position: relative;
    @media (max-width: 992px) {
        padding: 50px 0;
    }
`
export const ServiceMainTitle = styled.h2`
    font-size: ${SIZES.xxxl};
    color: ${COLORS.black_primary};
    font-weight: ${FONT_WEIGHT.bold_lg};
    text-align: center;
    margin-bottom: 50px;
    text-transform: capitalize;
    @media (max-width: 767px) {
        margin-bottom: 0;
    }
    @media (max-width: 992px) {
        font-size: 30px;
        line-height: 40px;
    }
`
export const ServieContentWrapper = styled.div`
    text-align: center;
    @media (max-width: 767px) {
        margin-top: 30px;
    }
`
export const ServiceTitle = styled.h4`
    font-size: ${SIZES.md};
    line-height: ${SIZES.md};
    color: ${COLORS.black_secondary};
    text-transform: capitalize;
    font-weight: 600;
    margin: 0;
    @media (min-width: 767px) and (max-width: 992px){
        font-size: 19px;
        line-height: 24px;
    }
`
export const ServiceImg = styled.img`
    width: 100%;
    margin: 30px auto;
    display: block;
    @media (max-width: 767px){
        margin: 20px auto;
    }
`
export const ServiceText = styled.p`
    font-size: ${SIZES.sm};
    text-align: center;
    line-height: 26px;
    color: #000;
    margin:0;
`
export const AppWrapper = styled.div`
    display: flex;
    flex-wrap:nowrap;
    width:100%;
    position: relative;
    @media (max-width: 767px) {
        flex-wrap: wrap;
        flex-direction: column;
    }
`
export const AppTextCol = styled.div`
    min-width:50%;
    max-width:50%;
    background-color: ${COLORS.primary_color};
    height: 600px;
    @media (max-width: 767px) {
        order: 2;
        min-width:100%;
        max-width:100%;
        height: auto;
        padding: 50px 0;
    }
    @media (min-width: 768px) and (max-width: 992px) {
        height: 350px;
    }
`
export const AppInnerContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:100%;
    padding: 0 30px 0 56px;
    @media (max-width: 992px) {
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0 15px;
    }
    .app-download-button {
        border: 0;
        background: none;
        outline: none;
        padding:0;
        cursor: pointer;
    }
`
export const AppImgCol = styled.div`
    min-width:50%;
    max-width:50%;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.white_color};
    @media (max-width: 767px) {
        order: 1
        min-width:100%;
        max-width:100%;
    }
    img {
        display: block;
        width: 100%;
    }
`

export const AppTitle = styled.h4`
    font-size: ${SIZES.xl};
    color: ${COLORS.white_color};
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 30px;
    @media (max-width: 767px) {
        font-size: 24px;
        margin-bottom: 20px;
        text-align: center;
    }
`
export const AppText = styled.p`
    font-size: ${SIZES.sm};
    line-height: 26px;
    color: ${COLORS.white_color};
    margin-bottom: 30px;
    @media (max-width: 767px) {
        text-align: center;
    }
`
export const GetUpdateWrapper = styled.div`
    background-color: ${COLORS.white_color};
    padding: 100px 16px;
    text-align:center;
    position: relative;
    @media (max-width: 767px) {
        padding: 50px 16px;
    }
`
export const GetUpdateTitle = styled.h2`
    font-size: ${SIZES.xxxl};
    color: ${COLORS.black_primary};
    font-weight: ${FONT_WEIGHT.bold_md};
    line-height: normal;
    margin-bottom: 30px;
    text-transform: capitalize;
    &::after{
        content: "";
        display: block;
        background-color: ${COLORS.primary_color};
        height: 3px;
        width: 140px;
        margin: 10px auto 0;
    }
    @media (max-width: 767px) {
        font-size: 24px;
        margin-bottom: 20px;
    }
`
export const GetUpdateText = styled.p`
    font-size: ${SIZES.sm};
    color: ${COLORS.secondary_color};
    line-height: 26px;
    margin: 0 auto 30px;
    @media (min-width: 768px) {
        width: 680px;
    }
`
export const JoinNowBtn = styled(Link)`
    display: block;
    border: 1px solid ${COLORS.primary_color};
    border-radius: 6px;
    font-size: ${SIZES.sm};
    color: ${COLORS.white_color};
    background-color: ${COLORS.primary_color};
    height: 46px;
    line-height: 46px;
    text-transform: uppercase;
    text-align: center;
    width: 200px;
    transition: 0.3s;
    outline: none;
    margin: 0 auto;
    &:hover{    
        background-color: ${COLORS.white_color};
        color: ${COLORS.primary_color};    
    }
`
export const SocialLinkWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    @media (min-width: 768px) {
        padding-top: 50px;
        max-width: 280px;
        margin: 0 auto;   
    }

`
export const SocialLink = styled(Link)`
    font-size: 40px;
    color: ${COLORS.primary_color};
    line-height: 40px;
    &:hover {
        color: ${COLORS.primary_color};
    }
`
export const FollowUsTitle = styled(ServiceMainTitle)`
    margin: 0 auto;
    @media (max-width: 768px) {
        max-width: 400px;
    }
`
export const GetItText = styled.span`
    display: block;
    text-align: center;
    font-size: 14px;
    color: ${COLORS.secondary_color};

` 
export const AppRow = styled(Row)`
    @media(max-width: 767px) {
        justify-content: center;
    }
`
export const PlaystoreButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px 0;
    width: 300px;
    margin: 0 auto;
`
export const PlaystoreButton = styled.a`
     display: block;
    border: 1px solid ${COLORS.primary_color};
    border-radius: 6px;
    font-size: ${SIZES.sm};
    color: ${COLORS.white_color};
    background-color: ${COLORS.primary_color};
    height: 46px;
    line-height: 46px;
    text-transform: uppercase;
    text-align: center;
    transition: 0.3s;
    outline: none;
    width: 300px;
    &:hover{    
        background-color: ${COLORS.white_color};
        color: ${COLORS.primary_color};    
    }
`
