import React, { Component } from 'react';
import { Collapse } from "antd";
import {} from "@ant-design/icons";
import {BannerWrapper, 
        BannerTitle,
        FaqContainer} from "./faq.style"
const { Panel } = Collapse;


class Faqs extends Component {
    state = {  }
    render() {
        
        const Banner = () => {
            return (
                <BannerWrapper>
                    <BannerTitle>Faq's</BannerTitle>
                </BannerWrapper>
            )
        } 

        const Accordions = () => {
            return (
                <Collapse defaultActiveKey={['1']} ghost accordion>
                    <Panel header="Is the app FREE?" key="1">
                        <p>
                           YES. It’s free to download the app.
                        </p>
                    </Panel>
                    <Panel header="Where can I download the app?" key="2">
                        <p>
                            You may download the app from Apple Store or GooglePlay store 
                        </p>
                    </Panel>
                    <Panel header="Can I cancel the booking?" key="3">
                        <p>
                            Yes, you may cancel your booked appointment. Make sure that you cancel it 8 hours prior the set time  to prevent cancellation fees.
                        </p>
                    </Panel>
                    <Panel header="How do I know if the booking is confirmed?" key="4">
                        <p>
                            You may receive an e-mail notification and a call from our QA to confirm the booking.
                        </p>
                    </Panel>
                    <Panel header="How do I pay the PRO?" key="5">
                        <p>
                            You may use G-cash, bank card, check, or cash
                        </p>
                    </Panel>
                    <Panel header="Do I have to plug-in my bank card information?" key="6">
                        <p>
                            Yes, you may. It’s convenient and efficient.
                        </p>
                    </Panel>
                    <Panel header=" Can I pay with Cash?" key="7">
                        <p>
                            Absolutely!
                        </p>
                    </Panel>
                    <Panel header="As a PRO, do I have to maintain a wallet balance?" key="8">
                        <p>
                            Yes. In order to show up at the PRO results, you have to maintain a balance to cover up fee/s.
                        </p>
                    </Panel>
                    <Panel header="Can I give a cash tip?" key="9">
                        <p>
                            Yes and we encourage it.
                        </p>
                    </Panel>
                    <Panel header="As a PRO, can I receive a cash tip?" key="10">
                        <p>
                            Of course! 
                        </p>
                    </Panel>
                    <Panel header="I want to be a PRO, how can I apply to become one?" key="11">
                        <p>
                            Download the app, register as a PRO, fill-up the application and submit the requirements digitally  or in person. 
                        </p>
                    </Panel>
                    <Panel header="I want to advertise my business to your app, how do I do that?" key="13">
                        <p>
                            Send us an email at <a href="mailto:info@prajekpro.com" style={{color:"#3e54d3"}}>info@prajekpro.com</a> 
                            or you may send us an inquiry using CONTACT US 
                        </p>
                    </Panel>
                    <Panel header="Do I have to wear a face shield and face mask during the PRO visit?" key="14">
                        <p>
                            Yes. Please follow the government protocol in regards to COVID-19. 
                        </p>
                    </Panel>
                </Collapse>
            )
        }

        return ( 
            <>
                <Banner/>
                <FaqContainer>
                    <Accordions/>
                </FaqContainer>
            </>
        );
    }
}
 
export default Faqs;