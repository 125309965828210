import React, { Component } from "react";
import { SectionContainer } from "../../globalStyle";
import {
  BannerWrapper,
  BannerTitle,
  ContainerWrapper,
  SubTitle,
  Text,
} from "./data.policy.style";

class DataPolicy extends Component {
  state = {};
  render() {
    const Banner = () => {
      return (
        <BannerWrapper>
          <BannerTitle>Data policy</BannerTitle>
        </BannerWrapper>
      );
    };

    const TermsData = () => {
      return (
        <ContainerWrapper>
          <SectionContainer>
            <SubTitle>Definitions</SubTitle>
            <Text>
              PrajekPro is committed to providing you with a wonderful customer
              experience. We know how important your information is to you, and
              we want you to know that you can trust us to protect it.
            </Text>
            <Text>
              In this Privacy Policy, we tell you about how we collect, use, and
              share your information when you use our products and services.
            </Text>
            <SubTitle>PRAJEKPRO’S COMMITMENT</SubTitle>
            <Text style={{ color: "#000" }}>
              <strong>Openness, Transparency, and Notice</strong>
            </Text>
            <Text>
              We are open and honest with you and will ensure that you are
              provided with clear, prominent, and timely information regarding
              our data privacy practices. We will inform you about the
              collection, access, sharing, and further use of your information.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Purpose and Use</strong>
            </Text>
            <Text>
              We will limit the access, collection, sharing, disclosure, and
              further use of your information to meet legitimate and/or legal
              purposes.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>User Choice and Control</strong>
            </Text>
            <Text>
              We will give you opportunities to exercise meaningful choice and
              control over your information.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Data Minimization and Retention</strong>
            </Text>
            <Text>
              We will collect, access, and use only the minimum information
              necessary to meet legitimate business purposes and to deliver,
              provision, maintain, or develop products and services. Information
              will be kept for as long as necessary to fulfill the purpose for
              which it was collected or for legitimate business or legal
              purposes.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Respect User Rights</strong>
            </Text>
            <Text>
              You will be provided with information about and an easy means to
              exercise your rights over the use of your information.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Security</strong>
            </Text>
            <Text>
              Your information will be protected using reasonable safeguards
              appropriate to the sensitivity of the data.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Information</strong>
            </Text>
            <Text>
              You will be provided with information about privacy and security
              issues and ways to manage and protect your privacy.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Accountability and Enforcement</strong>
            </Text>
            <Text>
              All responsible persons and/or entities will be accountable for
              ensuring that these privacy principles are met.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>INFORMATION WE COLLECT AND USE</strong>
            </Text>
            <Text>
              Throughout PrajekPro’s relationship with you, we may collect and
              use your information to allow us to provide you with our products
              and services. When we do so, we will let you know exactly what
              information we are collecting, why, and what basis under the law,
              apart from consent, we rely on.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Basic Identifiers and Contact Information</strong>
            </Text>
            <Text>
              Your full name, date of birth, address, landline, mobile number,
              e-mail address, proof of identification (including
              government-issued IDs), and payment option details:
            </Text>
            <div style={{ paddingLeft: "16px" }}>
              <Text>1. To access PrajekPro’s app, products or service</Text>
              <Text>
                2. To validate your identity when you use our products and
                services
              </Text>
              <Text>3. To provide customer care and support</Text>
              <Text>
                4. To send you advisories, personalized advertisements,
                financial service offers, gifts, and freebies
              </Text>
              <Text>
                5. To process payment in PrajekPro’s platform and We collect
                this information through our physical and digital application
                forms when you avail of our products and services
              </Text>
            </div>
            <Text style={{ color: "#000" }}>
              <strong>Cookies</strong>
            </Text>
            <Text>
              We collect cookies, web beacons, small data text files, or similar
              technologies primarily to ensure that the core functions of our
              website and apps are optimally accessible to you.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>INFORMATION WE SHARE</strong>
            </Text>
            <Text>
              PrajekPro may outsource the processing of information to
              third-parties including vendors, service providers, and partners
              to fulfill the purposes we have described in this Privacy Policy.
              This includes the verification of the truthfulness and accuracy of
              the information you provided to us, and compliance with government
              requirements.
            </Text>
            <Text>
              They are only authorized to access and use information for the
              duration of our contract with them. We also require them to
              protect information with organizational, physical, and technical
              security measures that are consistent with this Privacy Policy.
            </Text>
            <Text>
              It is our policy to never share your information with any
              third-party unless we obtain your consent or are otherwise
              required or allowed by law to do so. In any case, we will let you
              know exactly what we are sharing, why, and what basis under the
              law, apart from consent, we rely on, before we do so.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>HOW WE PROTECT YOUR INFORMATION</strong>
            </Text>
            <Text>
              We secure and protect your information with appropriate safeguards
              to ensure confidentiality and privacy, prevent loss, theft, use
              for unauthorized purposes, and comply with the requirements of the
              law.
            </Text>
            <Text>
              To detect and mitigate evolving threats to information security
              and data privacy, we have implemented appropriate organizational,
              physical, and technical controls to protect your information
              including:
            </Text>
            <div style={{ paddingLeft: "16px" }}>
              <Text>
                1. Security Operations Center complete with a dedicated team
                that manages, monitors, and protects our network and systems
                from potential risks to your information under fully documented
                security incident management procedures.
              </Text>
              <Text>
                2. Regular review of our collection, storage, and processing
                practices including physical and electronic security measures to
                guard against unauthorized access to our network and systems.
              </Text>
              <Text>
                3. Contractually mandated confidentiality among our authorized
                employees, contractors, and other third-parties who may have
                access to your information
              </Text>
              <Text>
                4. Third-party risk assessment as well as minimum-security
                features against data leakage, unauthorized access or
                disclosure, and accountability.
              </Text>
              <Text>
                5. Access management across PrajekPro employees, contractors,
                and other parties under a “need-to-know” standard.
              </Text>
            </div>
            <Text style={{ color: "#000" }}>
              <strong>HOW LONG WE KEEP YOUR INFORMATION</strong>
            </Text>
            <Text>
              We only keep your information while you are a customer or a
              partner Pro unless it is necessary to keep such information longer
              to fulfill the purposes for which it was collected or for
              legitimate business or legal purposes like security, fraud
              prevention, or financial record-keeping. Our retention schedule is
              available upon request.
            </Text>
            <Text>
              We have established procedures to securely dispose of files that
              contain your information whether in digital or physical form.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>YOUR RIGHTS AS A DATA SUBJECT</strong>
            </Text>
            <Text>
              We recognize your rights under the Data Privacy Act of 2012.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Right to Be Informed</strong>
            </Text>
            <Text>
              You have the right to be informed of how and why we collect and
              process your personal data, including where we collected it, with
              whom we share it and why, how we protect it, how long we keep it,
              and how we dispose of it, and any changes to our data processing
              activities before they are implemented.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Right to Object</strong>
            </Text>
            <Text>
              You have the right to object to the processing and/or sharing of
              your personal data especially when you feel that we do not have
              lawful grounds to do so. However, this may result in the
              termination of your PrajekPro app, product or service, when such
              data is necessary for its delivery.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Right to Withdraw Consent </strong>
            </Text>
            <Text>
              When our collection and processing of your personal data depends
              on the consent that you provided to us, you have the right to
              withdraw your consent at any time subject to PrajekPro’s internal
              procedures. This, however, does not apply to instances where we
              rely on grounds provided by law, other than consent. Nevertheless,
              you always have the right to object.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Right to Access </strong>
            </Text>
            <Text>
              You have the right to request reasonable access to your personal
              data subject to PrajekPro’s internal procedures. We, however,
              reserve the right to seek reasonable fees when providing such data
              presents certain challenges due to its volume as well as to
              restrict access to linked data that may be confidential,
              proprietary, or belong to other individuals.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Right to Data Portability</strong>
            </Text>
            <Text>
              You have the right to obtain a copy of your personal data or have
              it transmitted to other entities in a format that is portable and
              commonly accessible subject to PrajekPro’s internal procedures.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Right to Rectify</strong>
            </Text>
            <Text>
              We keep our records as accurate as possible. Where there are any
              errors or inaccuracies to your personal data, we give you ways to
              dispute and rectify or update it, subject to PrajekPro’s internal
              procedures.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Right to Erasure or Blocking</strong>
            </Text>
            <Text>
              You have the right to suspend, withdraw, or order the blocking,
              removal, or destruction of inaccurate, incomplete, outdated,
              false, or unlawfully obtained personal data, or personal data not
              necessary for the purpose for which it was collected, and for such
              other cases provided in the Data Privacy Act of 2012.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Right to Damages</strong>
            </Text>
            <Text>
              You have the right to be indemnified for damages sustained, if
              any, due to inaccurate, incomplete, outdated, false, unlawfully
              obtained, or unauthorized use of your personal data.
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>HOW TO REACH US</strong>
            </Text>
            <Text>
              Should you wish to exercise any of your data subject rights or
              have any questions or concerns regarding our privacy practices,
              you may contact our Data Protection Officer:
            </Text>
            <Text style={{ color: "#000" }}>
              <strong>Data Protection Officer</strong>
            </Text>
            <Text>
              <strong>Address:</strong>
              <span style={{ marginLeft: "10px" }}>
                Ph4 blk13 lot11 Wisteria st. villa de Primarosa buhay na tubig,
                imus, Cavite 4103
              </span>
            </Text>
            <Text>
              <strong>Email ID:</strong>
              <a
                href="mailto:inbox@prajekpro.com"
                style={{ marginLeft: "10px" }}
              >
                inbox@prajekpro.com
              </a>
            </Text>
          </SectionContainer>
        </ContainerWrapper>
      );
    };

    return (
      <>
        <Banner />
        <TermsData />
      </>
    );
  }
}

export default DataPolicy;
