import React, { Component } from "react";
import { SectionContainer } from "../../globalStyle";
import {
  BannerWrapper,
  BannerTitle,
  ContainerWrapper,
  SubTitle,
  Text,
  SubHeading,
  TitleSm,
} from "./privacy.style";

class TermsConditions extends Component {
  state = {};
  render() {
    const Banner = () => {
      return (
        <BannerWrapper>
          <BannerTitle>privacy policy</BannerTitle>
        </BannerWrapper>
      );
    };

    const TermsData = () => {
      return (
        <ContainerWrapper>
          <SectionContainer>
            <SubTitle>PRIVACY POLICY</SubTitle>
            <Text>
              Prajekpro and its affiliates (collectively "Prajekpro," "we,"
              "our," or "us") value your privacy. In this Privacy Policy
              ("Policy"), we describe the information we collect, how we use it,
              and when and to whom we disclose it. This Policy applies to all of
              our websites, mobile applications, and other online services
              (collectively, the "Platform") made available by or through
              Prajekpro and on which we post this Policy, including through our
              URL <a href="https://www.prajekpro.com/">www.prajekpro.com</a> and
              to information we collect offline or through other means.
              Undefined capitalized terms used herein shall have the definitions
              as set forth in our Terms of Use. By accessing or using the
              Platform, you agree to this Policy. IF YOU DO NOT AGREE TO THIS
              POLICY, PLEASE DO NOT ACCESS OR USE THE PLATFORM.
            </Text>
            <SubHeading>KEY TERMS</SubHeading>
            <Text className="mt-sm">
              "Content" means text, graphics, music, software, audio,
              video, information or other materials, including but not limited
              to profile information, Prajekpro Services requests, quotes,
              message threads, reviews, scheduling and calendar information, and
              other information or materials available on or through the
              Platform.
            </Text>
            <Text className="mt-sm">
              "Consumer" means a User who is registered to search for Service
              Professionals on the Platform, seeks Prajekpro Services from
              Service Professionals on the Platform, makes a booking with a
              Service Professional to purchase a Prajekpro Service (or receive a
              quote in contemplation thereof), is registered for or undergoes a
              home specialist consultation or similar service with respect to
              their home, utilizes a Prajekpro plan to track maintenance on a
              home, is a Prajekpro member, or otherwise uses the Platform to
              receive, pay for, review, or facilitate the receipt of Pro
              Services. Because a Consumer may be, or may become, a customer of
              a Service Professional, a Consumer is often referred to as a
              “customer” or a “Customer” on the Platform and in marketing
              materials related to the Platform.
            </Text>
            <Text className="mt-sm">
              "Platform" has the meaning set forth in the opening paragraph
              above.
            </Text>
            <Text className="mt-sm">
              "Prajekpro Services" means the services listed, quoted, scheduled,
              fulfilled or provided by Service Professionals, or sought,
              scheduled, paid for or received by Consumers, through the
              Platform.
            </Text>
            <Text className="mt-sm">
              "Service Professional" means a User who uses, or is registered to
              use, the Platform to offer, provide, receive payment for, or
              facilitate the provision of Prajekpro Services. Service
              Professionals are often referred to as “pros” on the Platform.
            </Text>
            <Text className="mt-sm">
              "Prajekpro Content" means all Content Prajekpro makes available on
              or through the Platform, including any Content licensed from a
              third-party, but excluding User Content.
            </Text>
            <Text className="mt-sm">
              "User" means a person or entity who uses the Platform, including,
              for example, a person who completes Prajekpro's account
              registration process, agrees to the Terms of Use, utilizes any
              services offered by or through the Platform, or submits or
              receives a request through Prajekpro, including but not limited to
              Service Professionals and Consumers.
            </Text>
            <Text className="mt-sm">
              "User Content" means all Content submitted, posted, uploaded,
              published, or transmitted on or through the Platform by any User
              of the Platform, including but not limited to photographs, profile
              information, descriptions, postings, reviews, requests, messages,
              and payments made through the Platform, but excluding Prajekpro
              Content and Feedback.
            </Text>
            <SubTitle>INFORMATION WE COLLECT</SubTitle>
            <Text className="mt-sm">
              How we collect and store information depends on how you access and
              use the Platform. We collect information in multiple ways
              including when you provide information directly to us, when we
              collect information from affiliates and non-affiliated sources,
              and when we passively collect information from you, such as
              information collected from your browser or device.
            </Text>
            <TitleSm>Information You Provide Directly to Us</TitleSm>
            <Text className="mt-sm">
              You may provide us with information about you when you use or
              access the Platform, such as when you register for an account;
              participate in polls or surveys; enroll for electronic
              newsletters; request a quote or other information; submit or
              respond to a quote; make a purchase; fill out any forms; enter a
              sweepstakes or contest, or register for a promotion; transmit User
              Content; download or use one of our mobile applications; you
              consent to our collection of information, such as when you grant
              us permission to collect your device’s contact list; or otherwise
              communicate with us or other Users through the Platform. This list
              is illustrative, not exhaustive; this Policy applies to all your
              uses of the Platform.
            </Text>
            <Text className="mt-sm">
              The personal information you provide directly to us may include,
              but is not limited to:
            </Text>
            <Text className="mt-sm">1. name</Text>
            <Text className="mt-sm">2. postal or zip code</Text>
            <Text className="mt-sm">3. email address</Text>
            <Text className="mt-sm">4. home or business telephone number</Text>
            <Text className="mt-sm">5. home, business or mailing address</Text>
            <Text className="mt-sm">
              5. demographic information (e.g., gender, age, and other
              information relevant to User surveys and/or offers)
            </Text>
            <Text className="mt-sm">
              6. insurance information (if you are a Service Professional)
            </Text>
            <Text className="mt-sm">
              7. photographs and/or video files or audio files (e.g., customer
              service call recordings)
            </Text>
            <Text className="mt-sm">
              8. information about your project, home, request or need
            </Text>
            <Text className="mt-sm">
              9. in certain circumstances, payment information
            </Text>
            <Text className="mt-sm">
              10. if you are a Prajekpro Professional, in certain circumstances,
              identity verification information (such as Certificate number or
              driver’s license information); and/or
            </Text>
            <Text className="mt-sm">
              11. any other information you include in communications with other
              Users through the Platform or communications with us.
            </Text>
            <Text className="mt-sm">
              It may also include your information specific to the services you
              are requesting or offering through the Platform, such as a
              business name, service description, qualifications and
              credentials. You are not required to provide us with such
              information, but certain features of the Platform may not be
              accessible or available, absent the provision of the requested
              information.
            </Text>
            <Text className="mt-sm">
              We utilize a third-party payment provider to process payment
              information (e.g., credit card information or bank account
              information).
            </Text>
            <Text className="mt-sm">
              Information from Affiliates, Social Networking Sites, and Other
              Non-Affiliated Sources
            </Text>
            <Text className="mt-sm">
              We may collect information about you through Prajekpro affiliates
              or through non-affiliated sources, including public information
              sources. For example:
            </Text>
            <Text className="mt-sm">
              1. You may be able to access the Platform through a social
              networking account, such as Facebook. If you access the Platform
              through your Facebook account, you may allow us to have access to
              certain information in your Facebook profile. This may include
              your name, profile picture, gender, networks, user IDs, list of
              friends, location, date of birth, email address, photos, videos,
              people you follow and/or who follow you, and/or your posts or
              "likes." Social networking sites, such as Facebook, have their own
              policies for handling your information. For a description of how
              these sites may use and disclose your information, including any
              information you make public, please consult those sites' privacy
              policies.
            </Text>
            <Text className="mt-sm">
              2. To the extent permitted by law, we may, in our sole discretion,
              ask for and collect supplemental information about you from
              non-affiliated sources, such as information about your credit from
              a credit bureau, or information to verify your identity or
              trustworthiness, or for other fraud or safety protection purposes.
              We may combine information that we collect from you through the
              Platform with information that we obtain from such sources,
              including public information sources and information derived from
              any other products or services we provide.
            </Text>
            <Text className="mt-sm">
              3. We may collect information that demonstrates the occurrence of
              an off-Platform communication between a Consumer and a Service
              Professional in order to ensure accurate charging of fees imposed
              upon Service Professionals and to enforce the Terms of Use. Except
              as explicitly stated herein, we may not collect or store the
              contents of any off-Platform communication between a Consumer and
              a Service Professional.
            </Text>
            <Text className="mt-sm">
              4.If you send text messages with a Consumer or Service
              Professional using the telephone number for that User available on
              the Platform, we may use a third-party vendor to track these text
              messages. We may track these text messages for fraud prevention,
              to ensure appropriate charging of fees, to enforce our Terms of
              Use, and for quality and training purposes. As part of this
              process, Prajekpro and the third-party vendor may receive in real
              time and store data about your text message, including the date
              and time of the text message, your phone number, and the content
              of the text message.
            </Text>
            <Text className="mt-sm">
              Please note that we have no control over how any third-party site
              uses or discloses the information it collects about you.
            </Text>
            <SubTitle>
              Information That Is Passively or Automatically Collected
            </SubTitle>
            <Text className="mt-sm">
              Device/Usage Information. We and certain third-party vendors,
              including ad networks and analytics providers, may use cookies,
              web beacons, and other tracking technologies to collect
              information about the computers or devices (including mobile
              devices) you use to access the Platform. As described further
              below, we and these third-party vendors may collect and analyze
              information including but not limited to (a) browser type; (b) ISP
              or operating system; (c) domain name; (d) access time; (e)
              referring or exit pages; (f) page views; (g) IP address; (h)
              unique device identifiers (e.g. IDFA or Android ID); and (i) the
              type of device that you use. We may also track and record when and
              how frequently you access or use the Platform, or how you browse
              the Platform. We do not, however, collect keystroke information.
              We use this information (including the information collected by
              relevant third-party vendors) for Platform analytics (including to
              determine which portions of the Platform are used most frequently
              and what our Users like/do not like), to assist in determining
              relevant advertising (both on and off the Platform), to evaluate
              the success of our advertising campaigns, and as otherwise
              described in this Policy.
            </Text>
            <Text className="mt-sm">
              Location Information. When you use the Platform, we may collect
              general location information (such as your location as derived
              from your IP address). If you install our mobile app, depending on
              your device settings and any permissions you have granted, we may
              collect information about your precise geolocation, and we may use
              that information to improve the Platform, including providing you
              with location-based features (e.g., for identification of
              Prajekpro Services available near you). To deliver customized
              content and advertising, we may disclose your location information
              to our agents, vendors, or advertisers. If you access the Platform
              through a mobile device and you do not want your device to provide
              us with location-tracking information, you may be able to disable
              the GPS or other location-tracking functions on your device. See
              your device manufacturer's instructions for further details. If
              you disable certain functions, you may be unable to use certain
              parts of the Platform.
            </Text>
            <Text className="mt-sm">
              Cookies and Other Electronic Technologies. We and our third-party
              vendors may use cookies, clear GIFs, pixel tags, and other
              technologies that help us better understand User behavior,
              personalize preferences, perform research and analytics, and
              improve the Platform. These technologies, for example, may allow
              us to tailor the Platform to your needs, save your password in
              password-protected areas, track the pages you visit, help us
              manage content, and compile statistics about Platform usage. We
              also use certain of these technologies to deliver advertisements
              through the Platform that may interest you or to advertise
              ourselves and the Platform to you on other sites. We or our
              third-party vendors also may use certain of these technologies in
              emails to our Users to help us track email response rates,
              identify when our emails are viewed, and track whether our emails
              are forwarded.
            </Text>
            <Text className="mt-sm">
              You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but your browser may allow you to
              modify your browser settings to decline cookies if you prefer. If
              you disable cookies, you may be prevented from taking full
              advantage of the Platform, because it may not function properly.
              As we adopt additional technologies, we may also gather additional
              information through other methods.
            </Text>
            <SubTitle>HOW WE USE YOUR INFORMATION</SubTitle>
            <Text className="mt-sm">
              We may use your information for any of the following reasons
            </Text>
            <Text className="mt-sm">
              1. For the purposes for which you provided it
            </Text>
            <Text className="mt-sm">
              2. To enable you to use the services available through the
              Platform, including registering you for our services and verifying
              your identity and authority to use our services
            </Text>
            <Text className="mt-sm">
              3. For customer support and to respond to your inquiries
            </Text>
            <Text className="mt-sm">
              4. For internal record-keeping purposes
            </Text>
            <Text className="mt-sm">
              5. To administer surveys, sweepstakes, promotions, or contests
            </Text>
            <Text className="mt-sm">
              6. To track fees and process billing and payment including sharing
              with third-party payment gateways and payment vendors in
              connection with the Platform
            </Text>
            <Text className="mt-sm">
              7. To improve and maintain the Platform and for product
              development
            </Text>
            <Text className="mt-sm">
              8. To address fraud or safety concerns, or to investigate
              complaints or suspected fraud or wrongdoing
            </Text>
            <Text className="mt-sm">
              9. To market and advertise our services and the Platform,
              including to send promotional emails regarding new products from
              Prajekpro, special offers from Prajekpro, or other information
              that may interest you
            </Text>
            <Text className="mt-sm">
              10. As permitted under applicable law, to contact you by text
              message regarding certain services or information you have
              requested
            </Text>
            <Text className="mt-sm">
              11. As permitted under applicable law, to contact you by telephone
              or text message regarding Platform features, improvements, or
              other products and services that may interest you
            </Text>
            <Text className="mt-sm">
              12. For Prajekpro's market research purposes, including, but not
              limited to, the customization of the Platform according to your
              interests
            </Text>
            <Text className="mt-sm">
              13. To contact you with information about your use of the Platform
            </Text>
            <Text className="mt-sm">
              14. For other research and analytical purposes
            </Text>
            <Text className="mt-sm">
              15. To resolve disputes, to protect ourselves and other Users of
              the Platform, or third parties, including to protect property or
              safety; and to enforce any legal terms that govern your use of the
              Platform
            </Text>
            <Text className="mt-sm">
              For Service Professionals, we may display information related to
              your business, including your name or your business’s name,
              publicly on your profile, except to the extent we tell you we may
              not display such information. If the information you provide us
              upon signing up for an account differs from the information you
              provide us as part of the verification data we collect, we have
              sole discretion in determining which information may be displayed
              publicly on your profile.
            </Text>
            <Text className="mt-sm">
              We may combine information that we collect from you through the
              Platform with information that we obtain from affiliated and
              non-affiliated sources, and information derived from any other
              products or services we provide. We may aggregate and/or
              de-identify information we collect. We may use de-identified or
              aggregated information for any purpose, including without
              limitation for research and marketing purposes and may also
              disclose such information to any third parties, including without
              limitation, advertisers, promotional partners, sponsors, event
              promoters, and/or others.
            </Text>
            <Text className="mt-sm">
              We may, either directly or through third-party providers we engage
              to provide services to us, review, scan, or analyze your
              communications with other Users exchanged via the Platform or as
              otherwise described in this Policy for fraud prevention, risk
              assessment, regulatory compliance, investigation, product
              development, research and User support purposes. For example, as
              part of our fraud prevention efforts, we may scan and analyze
              messages to prevent fraud or improper actions. We may also scan,
              review or analyze messages for research and product development
              purposes, as well as to debug, improve and expand product
              offerings. By using the Platform or engaging in off-Platform
              communications tracked by Prajekpro, you consent that Prajekpro,
              in its sole discretion, may, either directly or through
              third-parties we engage to provide services to us, review, scan,
              analyze, and store your communications, whether done manually or
              through automated means.
            </Text>
            <TitleSm>HOW WE DISCLOSE YOUR INFORMATION</TitleSm>
            <Text className="mt-sm">
              Unless otherwise described in this Policy, we may also disclose
              the information that we collect as follows:
            </Text>
            <Text className="mt-sm">
              Affiliates. We may disclose your information to any Prajekpro
              affiliates.
            </Text>
            <Text className="mt-sm">
              Consent. We may disclose your information based on your consent to
              do so.
            </Text>
            <Text className="mt-sm">
              Vendors. We may provide your information to select third parties
              who perform services on our behalf. These third parties provide a
              variety of services to us including without limitation billing,
              sales, marketing, advertising, market research, fulfillment, data
              storage, analysis and processing, identity verification, fraud and
              safety protection and legal services.
            </Text>
            <Text className="mt-sm">
              Legal Requirements. We may disclose your information when required
              by law or when we believe in good faith that such disclosure is
              necessary to: (a) comply with subpoenas, court orders, or other
              legal process we receive; (b) establish or exercise our legal
              rights including enforcing and administering agreements with
              Users; or (c) defend Prajekpro against legal claims. While you may
              challenge the disclosure request, please be advised we may still
              be legally required to turn over your information.
            </Text>
            <Text className="mt-sm">
              Protection of Prajekpro and Others. We may disclose your
              information when we believe it appropriate to investigate,
              prevent, or take action regarding possible illegal activities,
              suspected fraud, situations involving potential threats to the
              physical safety of any person, violations of the Terms of Use or
              any other legal terms governing use of the Platform, and/or to
              protect our rights and property and the rights and property of
              others.
            </Text>
            <Text className="mt-sm">
              Business Transfers. As we continue to develop our business, we may
              sell, buy, merge or partner with other companies or businesses, or
              sell some or all our assets. In such contemplated or actual
              transactions or where there is any change of control of Prajekpro,
              your information may be among the disclosed and/or transferred
              assets.
            </Text>
            <Text className="mt-sm">
              App Store Providers. We may provide your identity and mobile
              device identifier to third-party app store providers (for example,
              the Apple App Store) to allow you to download our mobile apps.
            </Text>
            <Text className="mt-sm">
              Academics and Research. We may provide information about Users to
              third-parties for academic and research purposes, in anonymized or
              aggregated form.
            </Text>
            <Text className="mt-sm">
              You may post Content on or through our Platform, including, but
              not limited to, your comments, pictures, Service Professional
              profile, and other information. However, please be careful and
              responsible whenever you are online. Some information you provide
              and/or that we collect may be publicly accessible. For instance,
              registration for an account requires that you provide us with your
              name. If you register through Facebook, the Platform may use the
              name associated with your Facebook account. Your name (full name,
              or in some instances, your first name and last initial) may be
              visible to other Users. Depending on the circumstances, your name
              may be attached to your Content or information, such as scheduling
              of Prajekpro Services, service requests, reviews, participating in
              discussions or forums, messaging, and profile information. Certain
              other people, including other Users with whom you have interacted
              via the Platform, may see information about you that is attached
              to your name. For example (but without limitation), if you are a
              Consumer seeking Prajekpro Services, the description you provide
              of your desired services, along with your name, may be shown to
              some Service Professionals registered in the relevant category
              along with your name. Thus, other Users may be able to personally
              identify you based on Content you provide. Similarly, certain
              information we collect, including but not limited to when you last
              accessed the Platform, may be disclosed to other Users with whom
              you are interacting or otherwise made public.
            </Text>

            <SubHeading className="mt-sm">
              If you choose to post User Content on or through the Platform,
              such as through User-to-User messaging or through our review
              boards, forums, blogs, or other postings, that information: (a)
              may be or may become publicly available; (b) may be collected and
              used by third-parties with or without our knowledge; and (c) may
              be used by third parties (including other Users) in a manner that
              may violate this Policy or applicable law.
            </SubHeading>
            <SubTitle>ONLINE ANALYTICS AND TAILORED ADVERTISING</SubTitle>
            <TitleSm className="mt-sm">Analytics</TitleSm>
            <Text className="mt-sm">
              We may use third-party web analytics services on the Platform,
              such as those of Google Analytics. Providers of these services use
              cookies and similar technologies (as described in the Information
              That Is Passively or Automatically Collected section above) to
              help us analyze how Users use the Platform, including by noting
              the third-party website from which you arrive. The information
              collected by the technology may be disclosed to or collected
              directly by these providers. We may also use Google Analytics; to
              prevent Google Analytics from using your information for
              analytics, you may install the Google Analytics Opt-Out Browser
              Add-on.
            </Text>
            <TitleSm className="mt-sm">Tailored Advertising</TitleSm>
            <Text className="mt-sm">
              We may use advertising services provided by third parties on the
              Platform, which provide their services using cookies and similar
              technologies (as described in the Information That Is Passively or
              Automatically Collected section above). Third parties whose
              products or services are accessible or marketed via the Platform
              may also place such technologies on your computer, mobile phone,
              or other device to collect information about your use of the
              Platform. We, our providers, and third parties collect information
              in this way for advertising and marketing purposes.
            </Text>
            <Text className="mt-sm">
              Certain third-party providers and certain cookies or similar
              technologies on the Platform enable us and third parties to serve
              you “tailored” advertising online that is more relevant to you and
              your interests. If you are interested in more information about
              tailored browser advertising and how you can generally control
              cookies from being put on your computer to deliver tailored
              advertising, you may visit the Network Advertising Initiative's
              Consumer Opt-Out Link and/or the Digital Advertising Alliance's
              Consumer Opt-Out Link to opt out of receiving tailored advertising
              from companies that participate in those programs. To opt out of
              Google Analytics for Display Advertising or customize Google
              Display Network ads, you can visit the Google Ads Settings page.
              We do not control any of these opt-out mechanisms and are not
              responsible for any choices you make using these mechanisms or the
              continued availability or accuracy of these mechanisms. If your
              browsers are configured to reject cookies when you visit this
              opt-out page, or you subsequently erase your cookies, use a
              different computer or change web browsers, your NAI or DAA opt-out
              may no longer be effective. Additional information is available on
              NAI's and DAA's websites.
            </Text>
            <Text className="mt-sm">
              Please note that if you opt out of tailored advertising, you may
              still receive online advertisements about us, the Platform, or
              third parties on the Platform – however, such advertising is not
              tailored.
            </Text>
            <Text className="mt-sm">
              When using a mobile application, you may also receive tailored
              in-application advertising. Each operating system (e.g., iOS for
              Apple devices, Android for Android devices, and Windows for
              Microsoft devices) provides its own instructions on how to prevent
              use of your mobile advertising identifier to deliver tailored
              in-application advertising. For more information, you may review
              the support materials and/or the privacy settings for the
              operating systems and devices you use, or contact the applicable
              provider/operator.
            </Text>
            <SubTitle>PRIVACY OF MINORS</SubTitle>
            <Text className="mt-sm">
              Our services are not provided to minors (i.e., persons under the
              age of 18). We elect to provide services only to persons 18 years
              of age or older, including any use of the Platform. If we
              discover, or if you inform us, that an individual under 18 has
              provided us with personal information, we may close the account
              and delete that individual’s personal information as required by
              law, including the Children's Online Privacy Protection Act. We
              may, where permitted by law, retain certain information internally
              for purposes described in this Policy.
            </Text>
            <SubTitle>SECURITY</SubTitle>
            <Text className="mt-sm">
              We employ physical, procedural, and technological security
              measures to help protect your information from unauthorized access
              or disclosure. Prajekpro may use encryption, passwords, and
              physical security measures to help protect your information
              against unauthorized access and disclosure. No security measures,
              however, are 100% failsafe. Therefore, we do not promise and
              cannot guarantee, and thus you should not expect, that any
              information provided or transmitted online is completely secure.
              You should take steps to protect against unauthorized access to
              your password, phone, and computer by, among other things, signing
              off after using a shared computer, choosing a robust password that
              nobody else knows or can easily guess, keeping your log-in and
              password private, and not recycling passwords from other websites
              or accounts. Prajekpro is not responsible for the unauthorized use
              of your information nor for any lost, stolen, or compromised
              passwords, or for any activity on your account via unauthorized
              password activity.
            </Text>
            <SubTitle>LINKS TO EXTERNAL PLATFORMS</SubTitle>
            <Text className="mt-sm">
              The Platform may contain links to third-party websites or
              resources over which Prajekpro does not have any control; please
              review such other websites’ privacy policies as to their use of
              personal information. Such links do not constitute an endorsement
              by Prajekpro of those third-party websites. You acknowledge that
              Prajekpro is providing these links to you only as a convenience,
              and further agree that Prajekpro is not responsible in any way for
              such third-party websites, including without limitation for their
              content or how they treat information you provide to them.
            </Text>
            <SubTitle>YOUR CHOICES</SubTitle>
            <Text className="mt-sm">
              You may review, correct, and delete certain information about you
              through your account, such as by logging in to the Platform and
              navigating to your preferences page in "Your Dashboard." We expect
              you to promptly update your account information if it changes or
              is inaccurate. Upon your request, we may close your account and
              remove your profile information from view as soon as reasonably
              possible. We may retain information from closed accounts in order
              to comply with the law, prevent fraud, collect any fees owed,
              resolve disputes, troubleshoot problems, assist with any
              investigations of any User, enforce our Terms of Use, and/or for
              any other purposes otherwise permitted by law that we deem
              necessary in our sole discretion. You should understand, however,
              that once you transmit User Content through the Platform, it may
              be acquired by others who may not be required to delete or modify
              such User Content. Once we have deactivated or removed your
              account, you agree that Prajekpro may not be responsible for
              retaining information related to your account.
            </Text>
            <Text className="mt-sm">
              We may send periodic emails to you. You may opt out of promotional
              emails by following the opt-out instructions contained in the
              email. Please note that it may take up to 10 business days for us
              to process opt-out requests. If you opt out of receiving
              promotional emails, we may still send you emails about your
              account or any services you have requested or received from us,
              transactions, or changes to our Terms of Use, this Policy, or
              other relevant terms.
            </Text>
            <Text className="mt-sm">
              We may monitor and record our telephone conversations with you for
              training and quality assurance purposes. You may be provided with
              a notice at the beginning of any
            </Text>
            <SubTitle>CHANGES TO THIS POLICY</SubTitle>
            <Text className="mt-sm">
              THIS POLICY IS CURRENT AS OF THE EFFECTIVE DATE SET FORTH ABOVE.
              PRAJEKPRO MAY, IN ITS SOLE AND ABSOLUTE DISCRETION, CHANGE THIS
              POLICY AT ANY TIME. PRAJEKPRO may POST ITS UPDATED POLICY ON THE
              PLATFORM, SEND YOU A MESSAGE OR OTHERWISE NOTIFY YOU WHEN YOU ARE
              LOGGED INTO YOUR ACCOUNT. PRAJEKPRO ENCOURAGES YOU TO REVIEW THIS
              POLICY REGULARLY FOR ANY CHANGES. YOUR CONTINUED ACCESS TO OR USE
              OF THE PLATFORM may BE SUBJECT TO THE TERMS OF THE THEN-CURRENT
              POLICY.
            </Text>
            <SubTitle>CONSENT TO TRANSFER</SubTitle>
            <Text className="mt-sm">
              Our computer systems are currently based in the Philippines and
              India, so your information may be processed by us in the
              Philippines and India, where data protection and privacy
              regulations may not offer the same level of protection as in your
              country of residence and data may be accessible to law enforcement
              and national security authorities under certain circumstances. If
              you create an account with the Platform as a visitor from outside
              the Philippines and India, by using the Platform, you agree to
              this Policy and you consent to the processing of all such
              information in the Philippines and India.
            </Text>
            <SubTitle>CONTACT US</SubTitle>
            <Text className="mt-sm">
              If you have any questions about this Policy or the Platform,
              please contact us by discretion.
            </Text>
            <SubTitle>Retention of Your Personal Information</SubTitle>
            <Text className="mt-sm">
              We may retain your personal information for as long as reasonably
              necessary to provide the Platform and our services or until you
              delete or modify your information. We also reserve the right to
              retain and use your information as necessary to comply with our
              legal obligations, resolve disputes, and enforce our agreements.
            </Text>
            <Text className="mt-sm">
              required to verify your identity before we fulfill your request.
              To do so, you may need to provide the information identified in
              the webform or as otherwise directed by us.
            </Text>
          </SectionContainer>
        </ContainerWrapper>
      );
    };

    return (
      <>
        <Banner />
        <TermsData />
      </>
    );
  }
}

export default TermsConditions;
