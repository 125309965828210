import React, { Component } from 'react';
import { SectionContainer } from "../../globalStyle"
import {BannerWrapper, 
        BannerTitle,
        ContainerWrapper,
        SubTitle,
        Text,
        BorderTitle,
        SecondaryContainer} from "./note.style"

class CovidNote extends Component {
    state = {  }
    render() {
        const Banner = () => {
            return (
                <BannerWrapper>
                    <BannerTitle>covid note</BannerTitle>
                </BannerWrapper>
            )
        } 

        const TermsData = () => {
            return (
                <ContainerWrapper>
                    <SectionContainer>
                        <Text>
                            Our goal is  to help you find the right people who can help you get your project done. 
                            If you are a customer looking for a pro because you need help, or if you are a Pro wanting to 
                            expand your business using our platform, we are here to connect the dots. However, 
                            we are facing a season of pandemic and things have been challenging. We hear you, we miss 
                            giving hugs too! We miss shaking your hands, and most of all, we miss seeing your smile! 
                            Professional Medical experts urge us to  wash our hands regularly, wear mask and face shield, 
                            and keep at least 6-ft away from each other
                        </Text>
                        <Text>
                            Do you know what’s amazing about being in this pandemic season?  We learn one of our common strengths- adaptable. 
                            We embrace the challenge and adapt into the situation. Don’t worry! You are not alone, we are all in this together. 
                            Let’s all stay informed, be safe, and keep smiling!
                        </Text>
                        <SubTitle>Process for Customers</SubTitle>
                        <Text>1. Set up the appointment and respect the cancelation policy</Text>
                        <Text>2. Keep your distance from the Pro. Wear a mask and a face shield.</Text>
                        <Text>3. Use digital payments instead of cash or check.</Text>
                        <Text>4. Consider giving tips to your Pro.</Text>

                        <SubTitle>Process for Pros</SubTitle>
                        <Text>1. Accept the appointments and respect the cancelation process.</Text>
                        <Text>2. Keep and maintain (1 meter away) your distance from the customer. Wear a mask and a face shield.</Text>
                        <Text>3. Wear gloves during the job.</Text>
                        <Text>4. Sanitize the surfaces you touched during the job.</Text>
                        <SecondaryContainer>
                            <BorderTitle>What is COVID-19?</BorderTitle>
                            <Text>
                                A coronavirus is a virus that causes respiratory infections. COVID-19 is the infectious disease caused by the most recently discovered coronavirus.
                            </Text>
                            <Text>
                                According to the {" "}<a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html" target="_blank" style={{color:"#3e54d3",textDecoration:"underline"}}>CDC</a>,{" "} 
                                the most common symptoms of COVID-19 are fever, tiredness and dry cough. COVID-19 can be easily 
                                spread by people who are asymptomatic — people who don’t feel ill at all. Older people and those 
                                with underlying medical problems are more likely to develop serious illness. People with fever, 
                                cough and difficulty breathing should seek medical attention.
                            </Text>
                            <SubTitle>How do I stop the spread?</SubTitle>
                            <Text>
                                Here’s what the {" "}<a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html" target="_blank" style={{color:"#3e54d3",textDecoration:"underline"}}>CDC</a> {" "} recommends:
                            </Text>
                            <Text>
                                <strong>Wash your hands.</strong> Washing them thoroughly for at least 30 seconds
                                 with soap and water helps kill the virus. An alcohol-based hand rub is the next 
                                 best option if you can’t wash your hands.
                            </Text>
                            <Text>
                                <strong>Avoid close contact.</strong> Put 1 meter of distance between yourself 
                                and people who don’t live in your household.
                            </Text>
                            <Text>
                                <strong>Cover your mouth and nose.</strong> Everyone should wear a cloth face cover 
                                in public settings and when around people who don’t live in your 
                                household, especially when other social distancing measures are 
                                difficult to maintain.
                            </Text>
                            <Text>
                                <strong>Cover your cough or sneeze.</strong> Use your elbow, not your hands. Or use a tissue, and then throw it away immediately.
                            </Text>
                            <Text>
                                <strong>Clean and disinfect.</strong> Clean AND disinfect frequently touched surfaces daily. This includes tables, doorknobs, light switches, countertops, handles, desks, phones, keyboards, toilets, faucets and sinks.
                            </Text>
                            <Text>
                                <strong>Monitor your health daily.</strong> Be alert for symptoms. Watch for fever, cough, shortness of breath or other symptoms of COVID-19.
                            </Text>
                            <SubTitle>
                                Where can I get more info?
                            </SubTitle>
                            <Text>
                                The situation is changing hour by hour. We recommend listening to the {" "}<a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html" target="_blank" style={{color:"#3e54d3",textDecoration:"underline"}}>CDC</a> {" "} and your local health officials. Some states and counties have stricter guidelines in place.
                            </Text>
                        </SecondaryContainer>
                    </SectionContainer>
                </ContainerWrapper>
            )
        }

        return ( 
            <>
                <Banner/>
                <TermsData/>
            </>
        );
    }
}
 
export default CovidNote;