import styled, {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`
    html,
    body{
    height:100%;
    width:100%;    
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin:0
    }
`
export const SectionContainer = styled.div`
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;

@media (min-width: 576px) {
    max-width: 540px;
}

@media (min-width: 768px) {
    max-width: 720px;
}    

@media (min-width: 992px) {
    max-width: 960px;
}

@media (min-width: 1200px) {
    max-width: 1140px;
}

@media (min-width: 1366px) {
    max-width: 1280px;
}

@media (min-width: 1920px) {
    max-width: 1840px;
}
`
export const ContainerFluid = styled.div`
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
` 
export const ClearFix = styled.div`
    clear: both;
`
export default GlobalStyle