import styled from "styled-components";
import { Button } from "antd";
import { COLORS, SIZES, FONT_WEIGHT } from "../../constants/theme";
import { Link } from "react-router-dom"; 
import BannerImg from "./images/banner.png" 
import {SectionContainer} from "../../globalStyle"


export const BannerWrapper = styled.div`
    background: ${COLORS.primary_color} url(${BannerImg}) no-repeat 96% center;
    height: 200px;
    background-size: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 50px;
    @media(max-width:767px) {
        height: 150px;
        background-size: 150px;
        padding: 0 15px;
    }
`
export const BannerTitle = styled.h1`
    font-weight: ${FONT_WEIGHT.bold_lg};
    line-height: normal;
    color: ${COLORS.white_color};
    margin: 0;
    text-transform: uppercase;    
    @media (min-width: 768px) {
        font-size: ${SIZES.xxxl};
    }
`
export const FaqContainer = styled(SectionContainer)`
    padding-top: 70px;
    padding-bottom: 100px;
    @media (max-width: 992px) {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        font-size: 18px;
        font-weight: ${FONT_WEIGHT.bold_sm};
        color: ${COLORS.white_color};
        line-height: 25px;
        color: ${COLORS.black_primary};
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        padding-left: 40px;
    }
    .ant-collapse-content-box > p {
        margin-bottom: 0;
        font-size: 16px;
        color: ${COLORS.secondary_color};
    }    
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .anticon {
        display: none;
    }
    
    .ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
    content: "";
        display: block;
        height: 2px;
        width: 24px;
        background: ${COLORS.black_primary};
        position: absolute;
        left: 0;
        top: 30px;
        transition: all 0.4s ease 0s;
    }
    
    .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header::before,
    .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header::after {
        transform: rotate(-45deg);
    }
    
    .ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
    content: "";
        display: block;
        height: 24px;
        width: 2px;
        background: ${COLORS.black_primary};
        position: absolute;
        left: 11px;
        top: 20px;
        transition: all 0.1s ease 0s;
    }

    .ant-collapse-ghost > .ant-collapse-item {
        border-bottom: 0;
        box-shadow: 0 0 10px 0 #eee;
        border-radius: 6px;
        margin-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header::after {
        left: 11px;
        top: 19px;
    }
`