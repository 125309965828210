import React, { Component } from "react";
import { SectionContainer } from "../../globalStyle";
import {
  BannerWrapper,
  BannerTitle,
  ContainerWrapper,
  SubTitle,
  Text
} from "./terms.style";

class TermsConditions extends Component {
  state = {};
  render() {
    const onFinish = values => {
      console.log(values);
    };
    const Banner = () => {
      return (
        <BannerWrapper>
          <BannerTitle>terms & conditions</BannerTitle>
        </BannerWrapper>
      );
    };

    const TermsData = () => {
      return (
        <ContainerWrapper>
          <SectionContainer>
            <SubTitle>
              These terms and conditions outline the rules and regulations for
              the use of Prajekpro's Application, located at
              <a
                href="javascript:void(0)"
                style={{ marginLeft: "5px", color: "#3e54d3" }}
              >
                www.prajekpro.com.
              </a>
            </SubTitle>
            <Text>
              By accessing this application assume you accept these terms and
              conditions. Do not continue to use Prajekpro if you do not agree
              to take all of the terms and conditions stated on this page.
            </Text>
            <Text>
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              "Client", "You" and "Your" refers to you, the person log on this
              application and compliant to the Company’s terms and conditions.
              "The Company", "Ourselves", "We", "Our" and "Us", refers to our
              Company. "Party", "Parties", or "Us", refers to both the Client
              and ourselves. All terms refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client’s needs in respect of
              provision of the Company’s stated services, in accordance with and
              subject to, prevailing law of Netherlands. Any use of the above
              terminology or other words in the singular, plural, capitalization
              and/or he/she or they, are taken as interchangeable and therefore
              as referring to same.
            </Text>
            <SubTitle>Cookies</SubTitle>
            <Text>
              We employ the use of cookies. By accessing Prajekpro, you agreed
              to use cookies in agreement with the Prajekpro's Privacy Policy.
            </Text>
            <Text>
              Most interactive applications use cookies to let us retrieve the
              user’s details for each visit. Cookies are used by our application
              to enable the functionality of certain areas to make it easier for
              people visiting our application. Some of our affiliate/advertising
              partners may also use cookies.
            </Text>
            <SubTitle>License</SubTitle>
            <Text>
              Unless otherwise stated, Prajekpro and/or its licensors own the
              intellectual property rights for all material on Prajekpro. All
              intellectual property rights are reserved. You may access this
              from Prajekpro for your own personal use subjected to restrictions
              set in these terms and conditions.
            </Text>
            <SubTitle>You must not:</SubTitle>
            <Text>1. Republish material from Prajekpro</Text>
            <Text>2. Sell, rent or sub-license material from Prajekpro</Text>
            <Text>3. Reproduce, duplicate or copy material from Prajekpro</Text>
            <Text>4. Redistribute content from Prajekpro</Text>
            <Text>
              This Agreement shall begin on the date here of. Our Terms and
              Conditions were created with the help of the Terms And Conditions
              Generator and the Privacy Policy Generator.
            </Text>
            <Text>
              Parts of this application offer an opportunity for users to post
              and exchange opinions and information in certain areas of the
              application. Prajekpro does not filter, edit, publish or review
              Comments prior to their presence on the application. Comments do
              not reflect the views and opinions of Prajekpro,its agents and/or
              affiliates. Comments reflect the views and opinions of the person
              who post their views and opinions. To the extent permitted by
              applicable laws, Prajekpro shall not be liable for the Comments or
              for any liability, damages or expenses caused and/or suffered as a
              result of any use of and/or posting of and/or appearance of the
              Comments on this application.
            </Text>
            <Text>
              Prajekpro reserves the right to monitor all Comments and to remove
              any Comments which can be considered inappropriate, offensive or
              causes breach of these Terms and Conditions.
            </Text>
            <SubTitle>You warrant and represent that:</SubTitle>
            <Text>
              1. You are entitled to post the Comments on our application and
              have all necessary licenses and consents to do so;
            </Text>
            <Text>
              2. The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party;
            </Text>
            <Text>
              3. The Comments do not contain any defamatory, libelous,
              offensive, indecent or otherwise unlawful material which is an
              invasion of privacy
            </Text>
            <Text>
              4. The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </Text>
            <Text>
              You hereby grant Prajekpro a non-exclusive license to use,
              reproduce, edit and authorize others to use, reproduce and edit
              any of your Comments in any and all forms, formats or media.
            </Text>
            <SubTitle>Hyperlinking to our Content</SubTitle>
            <Text>
              The following organizations may link to our application without
              prior written approval:
            </Text>
            <Text>1. Government agencies</Text>
            <Text>2. Search engines</Text>
            <Text>3. News organizations</Text>
            <Text>
              4. Online directory distributors may link to our application in
              the same manner as they hyperlink to the application of other
              listed businesses
            </Text>
            <Text>
              5. System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site.
            </Text>
            <SubTitle>
              We reserve the right to consider and approve link requests from
              the following types of organizations:
            </SubTitle>
            <Text>
              1. commonly-known consumer and/or business information sources.
            </Text>
            <Text>2. dot.com community sites.</Text>
            <Text>3. associations or other groups representing charities.</Text>
            <Text>4. online directory distributors.</Text>
            <Text>5. internet portals.</Text>
            <Text>6. accounting, law and consulting firms.</Text>
            <Text>7. educational institutions and trade associations.</Text>
            <SubTitle>
              We will approve link requests from these organizations if we
              decide that:
            </SubTitle>
            <Text>
              a. the link would not make us look unfavorably to ourselves or to
              our accredited businesses.
            </Text>
            <Text>
              b. the organization does not have any negative records with us.
            </Text>
            <Text>
              c. the benefit to us from the visibility of the hyperlink
              compensates the absence of Prajekpro.
            </Text>
            <Text>
              d. the link is in the context of general resource information.
            </Text>

            <SubTitle>
              These organizations may link to our home page so long as
            </SubTitle>
            <Text>a. the link is not in any way deceptive.</Text>
            <Text>
              b. does not falsely imply sponsorship, endorsement or approval of
              the linking party and its products or services.
            </Text>
            <Text>c. fits within the context of the linking party’s site.</Text>
            <Text>
              If you are one of the organizations listed in paragraph 2 above
              and are interested in linking to our application, you must inform
              us by sending an e-mail to Prajekpro. Please include your name,
              your organization name, contact information as well as the URL of
              your site, a list of any URLs from which you intend to link to our
              application, and a list of the URLs on our site to which you would
              like to link. Wait 2-3 weeks for a response.
            </Text>
            <SubTitle>
              Approved organizations may hyperlink to our application as
              follows:
            </SubTitle>
            <Text>1. By use of our corporate name; or</Text>
            <Text>
              2. By use of the uniform resource locator being linked to; or
            </Text>
            <Text>
              3. By use of any other description of our application being linked
              to that makes sense within the context and format of content on
              the linking party’s site.
            </Text>
            <Text>
              No use of Prajekpro's logo or other artwork will be allowed for
              linking absent a trademark license agreement.
            </Text>
            <SubTitle>iFrames</SubTitle>
            <Text>
              Without prior approval and written permission, you may not create
              frames around our Webpages that alter in any way the visual
              presentation or appearance of our application.
            </Text>
            <SubTitle>Content Liability</SubTitle>
            <Text>
              We shall not be hold responsible for any content that appears on
              your application. You agree to protect and defend us against all
              claims that is rising on your application. No link(s) should
              appear on any application that may be interpreted as libelous,
              obscene or criminal, or which infringes, otherwise violates, or
              advocates the infringement or other violation of, any third party
              rights.
            </Text>
            <SubTitle>Reservation of Rights</SubTitle>
            <Text>
              We reserve the right to request that you remove all links or any
              particular link to our application. You approve to immediately
              remove all links to our application upon request. We also reserve
              the right to amen these terms and conditions and it’s linking
              policy at any time. By continuously linking to our application,
              you agree to be bound to and follow these linking terms and
              conditions.
            </Text>
            <SubTitle>Removal of links from our application</SubTitle>
            <Text>
              If you find any link on our application that is offensive for any
              reason, you are free to contact and inform us any moment. We will
              consider requests to remove links but we are not obligated to or
              so or to respond to you directly. We do not ensure that the
              information on this application is correct, we do not warrant its
              completeness or accuracy; nor do we promise to ensure that the
              application remains available or that the material on the
              application is kept up to date.
            </Text>
            <SubTitle>Disclaimer</SubTitle>
            <Text>
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to our
              application and the use of this application. Nothing in this
              disclaimer will:
            </Text>
            <Text>
              1. limit or exclude our or your liability for death or personal
              injury.
            </Text>
            <Text>
              2. limit or exclude our or your liability for fraud or fraudulent
              misrepresentation.
            </Text>
            <Text>
              3. limit any of our or your liabilities in any way that is not
              permitted under applicable law.
            </Text>
            <Text>
              3. exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </Text>
            <Text>
              The limitations and prohibitions of liability set in this Section
              and elsewhere in this disclaimer:
            </Text>
            <Text>a. are subject to the preceding paragraph.</Text>
            <Text>
              b. govern all liabilities arising under the disclaimer, including
              liabilities arising in contract, in tort and for breach of
              statutory duty.
            </Text>
            <Text>
              As long as the application and the information and services on the
              application are provided free of charge, we will not be liable for
              any loss or damage of any nature.
            </Text>
            <SubTitle>Hyperlinking to our Content</SubTitle>
            <Text>
              These Terms and Conditions shall be governed by and construed in
              accordance with the laws of Philippines.
            </Text>
            <SubTitle>Venue of Action</SubTitle>
            <Text>
              In the event of any dispute, controversy, or claim arising from or
              relating to these Terms and Conditions, or the interpretation
              thereof, or any arrangements relating thereto or contemplated
              therein, or the breach, termination, or invalidity thereof, the
              parties hereto agree that venue shall be exclusively and properly
              set in the courts of Makati City, Philippines.
            </Text>
            <SubTitle>Assignment</SubTitle>
            <Text>
              Prajekpro reserves the right to assign any or all of its rights,
              duties and obligations hereunder to any third party without need
              of notice to or consent from the User. The User may not assign his
              rights and obligations under these Terms and Conditions without
              the prior written consent of Prajekpro.
            </Text>
            <SubTitle>Non-waiver</SubTitle>
            <Text>
              Failure by Prajekpro to exercise any or all of its rights
              hereunder, or any partial exercise thereof, shall not be construed
              as a waiver of such rights, and Prajekpro may, at any time,
              exercise any or all of the rights and discretions granted to it
              hereunder, or by law, without having to wait for the occurrence or
              re-occurrence of another or similar event which gives rise to such
              rights.
            </Text>
            <SubTitle>Severability</SubTitle>
            <Text>
              If any provision of these Terms and Conditions is or becomes
              invalid, illegal or unenforceable, the remaining provisions shall
              remain in full force and effect, and for the invalid, illegal or
              unenforceable provision shall be substituted a valid, legal and
              enforceable provision which shall be as similar as possible in
              economic and business objectives as intended by the parties.
            </Text>
          </SectionContainer>
        </ContainerWrapper>
      );
    };

    return (
      <>
        <Banner />
        <TermsData />
      </>
    );
  }
}

export default TermsConditions;
