import styled from "styled-components";
import { Button, Drawer } from "antd";
import { Link, NavLink } from "react-router-dom";
import { SectionContainer } from "../../globalStyle";
import {
  COLORS,
  SIZES,
  FONT_WEIGHT,
  headerHeight,
} from "../../constants/theme";
import { Alert } from "antd";

export const CovidMessage = styled.p`
  text-align: center;
  color: ${COLORS.primary_color};
  font-size: ${SIZES.sm};
  margin-bottom: 0;
  text-transform: uppercase;
  a {
    font-size: inherit;
    color: inherit;
    text-decoration: underline;
    text-transform: capitalize;
    &:hover {
      color: ${COLORS.primary_color};
      text-decoration: underline;
    }
  }
`;
export const CovidAlert = styled(Alert)`
  background-color: ${COLORS.light_blue};
  text-align: center;
  padding: 20px;
  .anticon-close {
    color: ${COLORS.primary_color};
    &:hover {
      color: ${COLORS.primary_color};
    }
  }
  @media (max-width: 767px) {
    padding: 20px 30px;
  }
`;
export const MainHeader = styled.header`
  background-color: ${COLORS.white_color};
  position: sticky;
  top: 0;
  height: ${headerHeight.size};
  box-shadow: rgba(43, 42, 42, 0.1) 0px 10px 10px;
  z-index: 999;
  @media (max-width: 992px) {
    height: 70px;
  }
  .app-download-button {
        border: 0;
        background: none;
        outline: none;
        padding:0;
        cursor: pointer;
    }
`;
export const HeaderContainer = styled(SectionContainer)`
  @media (max-width: 992px) {
    max-width: 100%;
  }
`;
export const TopMenu = styled.ul`
  padding-left: 0;
  list-style-type: none;
  margin: 0;
  @media (min-width: 992px) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media (max-width: 992px) {
    display: none;
  }
`;
export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const MenuList = styled.li`
  padding: 0 16px;
  @media (max-width: 992px) {
    padding: 0 0 20px;
  }
`;
export const MenuItem = styled(Link)`
  font-size: ${SIZES.sm};
  color: ${COLORS.primary_color};
  font-weight: ${FONT_WEIGHT.bold_lg};
  text-transform: uppercase;
  &:after {
    display: block;
    content: "";
    border-bottom: solid 3px ${COLORS.primary_color};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    @media (max-width: 992px) {
      border-bottom: none;
    }
  }
  &:hover {
    color: ${COLORS.primary_color};
    &:after {
      transform: scaleX(1);
      @media (max-width: 992px) {
        transform: none;
      }
    }
  }
`;
export const LogInBtn = styled.a`
  display: inline-block;
  font-size: ${SIZES.sm};
  color: ${COLORS.primary_color};
  padding: 7px;
  font-weight: ${FONT_WEIGHT.bold_lg};
  line-height: normal;
  background-color: ${COLORS.white_color};
  border: 1px solid ${COLORS.primary_color};
  border-radius: 6px;
  transition: 0.3s;
  text-transform: uppercase;
  text-align: center;
  width: 120px;
  &:hover {
    background-color: ${COLORS.primary_color};
    color: ${COLORS.white_color};
  }
`;
export const MenuBtnContainer = styled.div`
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 992px) {
    display: flex;
  }
`;

export const MenuBtn = styled(Button)`
  color: ${COLORS.white_color};
  font-size: ${SIZES.lg};
  height: 40px;
  width: 50px;
  margin-left: auto;
  background: ${COLORS.primary_color};
  border-color: ${COLORS.primary_color};
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  @media (max-width: 992px) {
    display: flex;
  }
  @media (min-width: 992px) {
    display: none;
  }
`;
export const MobileMenu = styled.ul`
  padding-left: 0;
  list-style-type: none;
  margin: 0;
`;
export const CloseDrawerBtn = styled(Button)`
  padding: 0;
  font-size: ${SIZES.xl};
  float: right;
  color: ${COLORS.primary_color};
`;
export const MenuDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 10px 20px 20px;
  }
`;

export const HeroBottomBtn = styled.a`
  display: block;
  border: 1px solid ${COLORS.primary_color};
  border-radius: 6px;
  font-size: ${SIZES.sm};
  color: ${COLORS.white_color};
  background-color: ${COLORS.primary_color};
  font-weight: 600;
  height: 46px;
  line-height: 46px;
  text-transform: uppercase;
  text-align: center;
  width: 160px;
  transition: 0.3s;
  outline: none;
  &:hover {
    background-color: ${COLORS.white_color};
    color: ${COLORS.primary_color};
  }
`;

export const PlaystoreButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px 0;
    width: 300px;
    margin: 0 auto;
`
export const PlaystoreButton = styled.a`
     display: block;
    border: 1px solid ${COLORS.primary_color};
    border-radius: 6px;
    font-size: ${SIZES.sm};
    color: ${COLORS.white_color};
    background-color: ${COLORS.primary_color};
    height: 46px;
    line-height: 46px;
    text-transform: uppercase;
    text-align: center;
    transition: 0.3s;
    outline: none;
    width: 300px;
    &:hover{    
        background-color: ${COLORS.white_color};
        color: ${COLORS.primary_color};    
    }
`
