import styled from "styled-components";
import {
  COLORS,
  SIZES,
  FONT_WEIGHT,
} from "../../constants/theme";
import { Link } from "react-router-dom";
import BannerImg from "./images/banner.png";
import { Modal } from "antd";


export const BannerWrapper = styled.div`
  background: ${COLORS.primary_color} url(${BannerImg}) no-repeat 96% center;
  height: 200px;
  background-size: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 767px) {
    height: 150px;
    background-size: 150px;
    padding: 0 15px;
    background-position: 100% center;
  }
`;

export const BannerTitle = styled.h1`
  font-weight: ${FONT_WEIGHT.bold_lg};
  line-height: normal;
  color: ${COLORS.white_color};
  margin: 0;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 24px;
  }
  @media (min-width: 768px) {
    font-size: ${SIZES.xxxl};
  }
`;
export const WhoWeAreContainer = styled.div`
  padding: 150px 0 100px;
  @media (max-width: 992px) {
    padding: 50px 0;
  }
`;

export const WhoWeAreTitle = styled.h2`
  font-size: 64px;
  color: ${COLORS.black_primary};
  line-height: normal;
  line-height: 70px;
  margin-bottom: 0;
  @media (max-width: 767px) {
    text-align: center;
    margin-top: 50px;
  }
  @media (max-width: 992px) {
    font-size: 40px;
    line-height: 50px;
  }
`;

export const WhoWeAreSubText = styled.p`
  font-size: ${SIZES.sm};
  color: ${COLORS.secondary_color};
  line-height: 26px;
  margin-top: 30px;
  margin-bottom: 0;
  letter-spacing: 0.02em;
`;

export const SlideItemWrapper = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
`;
export const WhoWeAreInnerContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const HowItWorksContainer = styled.div`
  padding: 100px 0;
  background-color: ${COLORS.light_blue};
  @media (max-width: 992px) {
    padding: 50px 0;
  }
`;
export const HowItWorksTitle = styled.h3`
  font-size: ${SIZES.xxxl};
  color: ${COLORS.black_primary};
  font-weight: ${FONT_WEIGHT.bold_md};
  line-height: normal;
  margin-bottom: 30px;
  text-transform: capitalize;
  text-align: center;
  &::after {
    content: "";
    display: block;
    background-color: ${COLORS.primary_color};
    height: 3px;
    width: 140px;
    margin: 10px auto 0;
  }
  @media (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;
export const HowItWorksText = styled.p`
  font-size: ${SIZES.sm};
  color: ${COLORS.secondary_color};
  line-height: 26px;
  margin: 0 auto;
  text-align: center;
  @media (min-width: 768px) {
    width: 700px;
  }
`;
export const PrajekProContainer = styled.div`
  padding: 100px 0;
  @media (max-width: 992px) {
    padding: 50px 0;
  }
  .profile-section {
    grid-row-gap: 32px !important;
    justify-content: center;
  }
`;
export const BehindPrajeckProTitle = styled.h3`
  font-size: ${SIZES.xxxl};
  color: ${COLORS.black_primary};
  font-weight: ${FONT_WEIGHT.bold_md};
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 24px;
  }
`;
export const TeamCard = styled.div`
  background-color: ${COLORS.white_color};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const TeamImg = styled.div`
  width: 100%;
  display: block;
  background-position: top center;
  height: 250px;
  background-size: cover;
`;
export const CardBody = styled.div`
  padding: 16px;
  text-align: center;
`;
export const TeamMate = styled.h4`
  font-size: 18px;
  color: ${COLORS.black_secondary};
  font-weight: ${FONT_WEIGHT.bold_sm};
  line-height: ${SIZES.md};
  margin-bottom: 0;
  @media (max-width: 992px) {
    font-size: 19px;
  }
`;
export const TeamDesc = styled.p`
  font-size: ${SIZES.sm};
  color: ${COLORS.secondary_color};
  margin-bottom: 0;
`;
export const SocialLink = styled(Link)`
  font-size: ${SIZES.md};
  color: ${COLORS.primary_color};
  margin-left: 20px;
  line-height: 24px;
  display: inline-block;
  &:hover {
    color: ${COLORS.primary_color};
  }
`;
export const Designation = styled.small`
  font-size: 14px;
  color: ${COLORS.primary_color};
  display: block;
  margin: 10px 0 16px;
`;

export const BioBtn = styled.button`
    display: block;
    border: 1px solid ${COLORS.primary_color};
    border-radius: 6px;
    font-size: ${SIZES.sm};
    color: ${COLORS.white_color};
    background-color: ${COLORS.primary_color};
    font-weight: 600;
    height: 35px;
    line-height: 30px;
    text-transform: uppercase;
    text-align: center;
    width: 160px;
    transition: 0.3s;
    outline: none;
    cursor: pointer;
    margin: 16px auto 0;
    &:hover{    
        background-color: ${COLORS.white_color};
        color: ${COLORS.primary_color};    
    }
`
export const BioModal = styled(Modal)`
  .ant-modal-title {
    text-align: center;
    font-size: ${SIZES.lg};
    color: ${COLORS.black_secondary};
  }
`