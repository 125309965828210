import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";
import GlobalStyle from "./globalStyle";
import { Header, Footer } from "./components";
import HomePage from "./pages/homepage/index";
import AboutUs from "./pages/aboutUs/index";
import ContactUs from "./pages/contactUs/index";
import TermsConditions from "./pages/terms/index";
import PrivacyPolicy from "./pages/privacy/index";
import Faqs from "./pages/faqs/index";
import CovidNote from "./pages/covidNote/index";
import CommingSoon from "./pages/commingSoon/index";
import ScrollToTop from "../src/components/ScrollToTop";
import DataPolicy from "./pages/dataPolicy/index";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <GlobalStyle />
      <Header />
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/terms-conditions" component={TermsConditions} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/faqs" component={Faqs} />
        <Route path="/covid-note" component={CovidNote} />
        <Route path="/comming-soon" component={CommingSoon} />
        <Route path="/data-policy" component={DataPolicy} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
