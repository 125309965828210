import React, { Component } from "react";
import styled from "styled-components";
import { COLORS, SIZES, FONT_WEIGHT } from "../../constants/theme";
import CommingSoonImg from "./images/comming-soon.png";
const Wrapper = styled.div`
  background: ${COLORS.white_color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  text-align: center;
`;
const ImgContainer = styled.div`
  width: 300px;
  min-width: 300px;
  margin: 0 auto;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
  }
  @media (min-width: 768px) {
    width: 300px;
  }
`;
const Title = styled.h1`
  font-size: 50px;
  color: ${COLORS.black_primary};
  font-weight: ${FONT_WEIGHT.bold_lg};
  line-height: 80px;
  text-transform: uppercase;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
  @media (max-width: 992px) {
    font-size: 36px;
    line-height: 50px;
  }
`;
class CommingSoon extends Component {
  state = {};
  render() {
    return (
      <Wrapper>
        <ImgContainer>
          <img src={CommingSoonImg} alt="Comming Soon" />
        </ImgContainer>
        <Title>we are launching soon</Title>
      </Wrapper>
    );
  }
}

export default CommingSoon;
