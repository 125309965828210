import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  COLORS,
  SIZES,
  FONT_WEIGHT,
  headerHeight,
} from "../../constants/theme";

export const FooterWrapper = styled.div`
  background-color: ${COLORS.primary_color};
  padding: 80px 0 30px;
  @media (max-width: 767px) {
    padding: 30px 0 30px;
  }
  .app-download-button {
        border: 0;
        background: none;
        outline: none;
        padding:0;
        cursor: pointer;
    }
`;
export const FooterLogo = styled.img`
  width: 100%;
  display: block;
  max-width: 170px;
`;
export const FooterTopLogo = styled.img`
  width: 100%;
  display: block;
  max-width: 230px;
`;
export const SectionHeading = styled.h4`
  font-size: ${SIZES.lg};
  color: ${COLORS.white_color};
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  font-weight: ${FONT_WEIGHT.bold_lg};
  @media (max-width: 767px) {
    font-size: ${SIZES.sm};
    margin-top: 30px;
  }
`;
export const FooterLink = styled(Link)`
  font-size: 14px;
  color: ${COLORS.white_color};
  line-height: normal;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 20px;
  font-weight: ${FONT_WEIGHT.bold_sm};
  &:hover {
    color: ${COLORS.white_color};
    text-decoration: underline;
  }
`;
export const FooterAppLink = styled.a`
  display: inline-block;
  margin-top: 20px;
`;
export const CopyrightWrapper = styled.div`
  border-top: 1px solid ${COLORS.white_color};
  padding-top: 30px;
  font-size: 14px;
  color: ${COLORS.white_color};
  text-align: center;
  margin-top: 30px;
  font-weight: ${FONT_WEIGHT.bold_sm};
`;

export const FooterLoginBtn = styled(Link)`
  display: block;
  border: 1px solid ${COLORS.white_color};
  border-radius: 6px;
  font-size: ${SIZES.sm};
  font-weight: ${FONT_WEIGHT.bold_sm};
  color: ${COLORS.white_color};
  background-color: transparent;
  height: 46px;
  line-height: 46px;
  text-transform: uppercase;
  text-align: center;
  width: 220px;
  transition: 0.3s;
  outline: none;
  margin: 20px 0 45px;
  &:hover {
    background-color: ${COLORS.white_color};
    color: ${COLORS.primary_color};
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const FooterAddress = styled.address`
  font-size: 14px;
  color: ${COLORS.white_color};
  line-height: 30px;
  margin-bottom: 0;
`;

export const FooterSection = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  gap: 0 8px;
  
  .anticon {
    color: white;
    margin-top: 8px;
  }
`;
export const PlaystoreButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px 0;
    width: 300px;
    margin: 0 auto;
`
export const PlaystoreButton = styled.a`
     display: block;
    border: 1px solid ${COLORS.primary_color};
    border-radius: 6px;
    font-size: ${SIZES.sm};
    color: ${COLORS.white_color};
    background-color: ${COLORS.primary_color};
    height: 46px;
    line-height: 46px;
    text-transform: uppercase;
    text-align: center;
    transition: 0.3s;
    outline: none;
    width: 300px;
    &:hover{    
        background-color: ${COLORS.white_color};
        color: ${COLORS.primary_color};    
    }
`
export const PropertyText = styled.p`
  margin-top: 16px;
  font-size: 14px;
  color: ${COLORS.white_color};
  text-align: center;
  @media (min-width: 768px) {
    max-width: 650px;
    margin-left: auto; 
    margin-right: auto; 
  }
`;