import styled from "styled-components";
import { COLORS, SIZES, FONT_WEIGHT } from "../../constants/theme";
import BannerImg from "./images/banner.png";

export const BannerWrapper = styled.div`
  background: ${COLORS.primary_color} url(${BannerImg}) no-repeat 96% center;
  height: 200px;
  background-size: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 767px) {
    height: 140px;
    background-size: 140px;
    padding: 0 15px;
  }
`;
export const BannerTitle = styled.h1`
  font-weight: ${FONT_WEIGHT.bold_lg};
  line-height: normal;
  color: ${COLORS.white_color};
  margin: 0;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 20px;
  }
  @media (min-width: 768px) {
    font-size: ${SIZES.xxxl};
  }
`;
export const ContainerWrapper = styled.div`
  padding: 50px 0 100px;
  @media (max-width: 767px) {
    padding: 30px 0 50px;
  }
`;
export const SubTitle = styled.h4`
  font-size: ${SIZES.md};
  font-weight: ${FONT_WEIGHT.bold_sm};
  line-height: normal;
  color: ${COLORS.black_primary};
  margin-bottom: 0;
  margin-top: 50px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 27px;
    margin-top: 20px;
  }
`;
export const Text = styled.p`
  font-size: ${SIZES.sm};
  line-height: 24px;
  color: ${COLORS.secondary_color};
  margin-top: 20px;
  margin-bottom: 0;
`;
