import React, { Component } from "react";
import { Row, Col, Modal } from "antd";
import { Link } from "react-router-dom";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  ArrowDownOutlined
} from "@ant-design/icons";
import { SectionContainer } from "../../globalStyle";
import {
  BgHeroWrapper,
  HeroTitle,
  HeroBottomText,
  HeroBottomBtn,
  BannerImg,
  BannerContainer,
  ServiceBgWrapper,
  ServiceMainTitle,
  ServieContentWrapper,
  ServiceTitle,
  ServiceImg,
  ServiceText,
  AppWrapper,
  AppTextCol,
  AppTitle,
  AppText,
  AppImgCol,
  AppInnerContent,
  GetUpdateWrapper,
  GetUpdateTitle,
  GetUpdateText,
  JoinNowBtn,
  SocialLinkWrapper,
  SocialLink,
  FollowUsTitle,
  GetItText,
  AppRow,
  PlaystoreButtonContainer,
  PlaystoreButton
} from "./Homepage.style";
import heroImg from "./images/deliveries.png";
import bookImg from "./images/book.png";
import serviceImg from "./images/service.png";
import doneImg from "./images/done.png";
import mobileAppImg from "./images/mobile-app.png";
import googlePlayBadge from "../../images/google-play-badge.png";
import appStoreBadge from "../../images/app-store-badge.png";
import { useState } from 'react';
import { Fragment } from "react/cjs/react.production.min";

class HomePage extends Component {
  state = {};
  render() {
    const HeroBanner = () => {
      return (
          <BgHeroWrapper>
          <SectionContainer>
            <Row gutter={16}>
              <Col md={12} xs={{ span: 24, order: 2 }}>
                <BannerContainer>
                  <HeroTitle>
                    Need Help For <div>Your Next Project?</div>
                  </HeroTitle>
                  <HeroBottomText>
                    Whether if you are looking for someone to fix your home
                    lights, looking for a caterer for your upcoming wedding, or
                    needing help in delivering your package, we got you! What
                    are you waiting for? Let’s get that project done!
                  </HeroBottomText>
                  <HeroBottomBtn href="https://play.google.com/store/apps/details?id=com.prajepro.customerapp">
                    Get it Done
                  </HeroBottomBtn>
                </BannerContainer>
              </Col>
              <Col md={12} xs={{ span: 24, order: 1 }}>
                <BannerImg src={heroImg} alt="Delivery" width="100%" />
              </Col>
            </Row>
          </SectionContainer>
        </BgHeroWrapper>
      );
    };

    const ServiceWorks = () => {
      return (
        <ServiceBgWrapper>
          <SectionContainer>
            <ServiceMainTitle>How Prajekpro works?</ServiceMainTitle>
            <Row gutter="16">
              <Col md={8} xs={24}>
                <ServieContentWrapper>
                  <ServiceTitle>book the appoinment</ServiceTitle>
                  <ServiceImg src={bookImg} alt="Book Appoinment" />
                  <ServiceText>
                    Type the project you are working on, or just simply use a
                    keyword at the search bar. Find the Pro that matches your
                    qualifications and book the appointment.
                  </ServiceText>
                </ServieContentWrapper>
              </Col>
              <Col md={8} xs={24}>
                <ServieContentWrapper>
                  <ServiceTitle>Meet Your Pro</ServiceTitle>
                  <ServiceImg src={serviceImg} alt="Book Appoinment" />
                  <ServiceText>
                    The pro will meet you and check-in at the agreed location,
                    and get your project done!
                  </ServiceText>
                </ServieContentWrapper>
              </Col>
              <Col md={8} xs={24}>
                <ServieContentWrapper>
                  <ServiceTitle>we are done</ServiceTitle>
                  <ServiceImg src={doneImg} alt="Book Appoinment" />
                  <ServiceText>
                    The pro will check-out and have you sign a complete project
                    report invoice. Give your pro a rating.
                  </ServiceText>
                </ServieContentWrapper>
              </Col>
            </Row>
          </SectionContainer>
        </ServiceBgWrapper>
      );
    };

    const HowAppWorks = () => {
      const [isModalOpen, setIsModalOpen] = useState(false);
      const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      return (
        <Fragment>
        <AppWrapper>
          <AppTextCol>
            <AppInnerContent>
              <AppTitle>DOWNLOAD The App now</AppTitle>
              <AppText>-Available at app store and google play store.</AppText>
              <AppRow gutter={16}>
                <Col span={8}>
                  <button type="button" onClick={showModal} className="app-download-button">
                   <img
                      src={googlePlayBadge}
                      alt="Google play Store"
                      style={{ display: "block", width: "100%" }}
                    />
                  </button>
                </Col>
                <Col span={8}>
                  <Link to="/comming-soon" style={{ display: "block" }}>
                    <img
                      src={appStoreBadge}
                      alt="App Store"
                      style={{ display: "block", width: "100%" }}
                    />
                  </Link>
                </Col>
              </AppRow>
            </AppInnerContent>
          </AppTextCol>
          <AppImgCol>
            <img src={mobileAppImg} alt="Mobile App" />
          </AppImgCol>
        </AppWrapper>
        <Modal title="Download App" centered open={isModalOpen} onOk={handleOk} onCancel={handleOk} footer={null}>
          <PlaystoreButtonContainer>
          <PlaystoreButton href="https://play.google.com/store/apps/details?id=com.prajekpro.custapp"><ArrowDownOutlined /> Download Cutsomer App</PlaystoreButton>
          <PlaystoreButton href="https://play.google.com/store/apps/details?id=com.prajekpro.profapp"><ArrowDownOutlined /> Download Professional App</PlaystoreButton>
          </PlaystoreButtonContainer>
        </Modal>
      </Fragment>
      );
    };

    const GetUpdates = () => {
      return (
        <GetUpdateWrapper>
          <SectionContainer>
            <GetUpdateTitle>Register To Become a Pro</GetUpdateTitle>
            <GetUpdateText>
              Whether if you are looking for a part time income or opportunities
              to expand your business, Prajekpro is here to help! Don’t wait,
              register and get it done!
            </GetUpdateText>
            <JoinNowBtn to="/">join now</JoinNowBtn>
          </SectionContainer>
        </GetUpdateWrapper>
      );
    };

    const FollowUS = () => {
      return (
        <ServiceBgWrapper>
          <SectionContainer>
            <FollowUsTitle>
              Follow Us On Your Favorite Social Media!
              <GetItText>#Prajekpro #Get It Done</GetItText>
            </FollowUsTitle>
            <SocialLinkWrapper>
              <SocialLink to="/">
                <FacebookOutlined />
              </SocialLink>
              <SocialLink to="/">
                <InstagramOutlined />
              </SocialLink>
              <SocialLink to="/">
                <TwitterOutlined />
              </SocialLink>
            </SocialLinkWrapper>
          </SectionContainer>
        </ServiceBgWrapper>
      );
    };
    return (
      <>
        <HeroBanner />
        <ServiceWorks />
        <HowAppWorks />
        <GetUpdates />
        <FollowUS />
      </>
    );
  }
}

export default HomePage;
