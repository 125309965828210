export const COLORS = {
    primary_color: '#3e54d3',
    secondary_color: '#6B809B',
    white_color:"#ffffff",
    black_primary: "#000000",
    black_secondary: "#333333",
    light_blue : "#e8f1fd",
};

export const SIZES =  {
    xs: "12px",
    sm: "16px",
    lg: "20px",
    md: "24px",
    xl: "28px",
    xxl:"32px",
    xxxl:"36px"
}

export const FONT_WEIGHT = {
    regular: "400",
    bold_sm: "500",
    bold_lg: "600",
    bold_md: "700",
    bold_xl: "900"    
}

export const headerHeight = {
    size : "80px"
}