import styled from "styled-components";
import { Button } from "antd";
import { COLORS, SIZES, FONT_WEIGHT } from "../../constants/theme";
import { Link } from "react-router-dom"; 
import BannerImg from "./images/banner.png" 

export const BannerWrapper = styled.div`
    background: ${COLORS.primary_color} url(${BannerImg}) no-repeat 96% center;
    height: 200px;
    background-size: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 50px;
    @media(max-width:767px) {
        height: 150px;
        background-size: 150px;
        padding: 0 15px;
        background-position: 100% center;
    }
`
export const BannerTitle = styled.h1`
    font-weight: ${FONT_WEIGHT.bold_lg};
    line-height: normal;
    color: ${COLORS.white_color};
    margin: 0;
    text-transform: uppercase;    
    @media (min-width: 768px) {
        font-size: ${SIZES.xxxl};
    }
`
export const BehindPrajeckProTitle = styled.h3`
    font-size: ${SIZES.xxxl};
    color: ${COLORS.black_primary};
    font-weight: ${FONT_WEIGHT.bold_md};
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 30px;
    @media (max-width: 767px) {
        font-size: 33px;
    }
`
export const DetailsWrapper = styled.ul`
    padding-left: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const DetailLists = styled.li`
    display: flex;
    align-items: center;
    margin-top: 30px;
    
    .anticon {
        font-size: 24px;
        color: ${COLORS.white_color};
        background-color: ${COLORS.primary_color};
        height: 60px;
        width: 60px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    } 
`
export const DetailField = styled.div`
    font-size: ${SIZES.sm};
    color: ${COLORS.black_primary};
    line-height: ${SIZES.md};
    margin-left: 20px;
    width: 200px;
    
`
export const FormContainer = styled.div`
    padding: 150px 0; 
    .ant-form-item-label > label {
        color: ${COLORS.secondary_color};    
    }
    @media (max-width: 992px) {
        padding: 50px 0;
    }
`
export const SubmitBtn = styled(Button)`
    display: block;
    border: 1px solid ${COLORS.primary_color};
    border-radius: 6px;
    font-size: ${SIZES.sm};
    color: ${COLORS.white_color};
    background-color: ${COLORS.primary_color};
    height: 46px;
    line-height: 35px;
    text-transform: uppercase;
    text-align: center;
    width: 200px;
    transition: 0.3s;
    outline: none;
    margin: 0 auto;
    &:hover,
    &:focus{    
        background-color: ${COLORS.white_color};
        border-color: ${COLORS.primary_color};
        color: ${COLORS.primary_color};    
    }
`