import React, { Component,Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import { Row, Col, Modal } from "antd";
import {
  InfoCircleOutlined,
  MenuOutlined,
  CloseOutlined,
  ArrowDownOutlined
} from "@ant-design/icons";
import { ClearFix } from "../../globalStyle";
import siteLogo from "../../images/logo.png";
import {
  CovidMessage,
  CovidAlert,
  MainHeader,
  TopMenu,
  LogoLink,
  MenuList,
  MenuItem,
  LogInBtn,
  MenuBtn,
  MenuBtnContainer,
  HeaderContainer,
  MobileMenu,
  CloseDrawerBtn,
  MenuDrawer,
  HeroBottomBtn,
  PlaystoreButtonContainer,
  PlaystoreButton
} from "./Header.style";
import { useState } from 'react';

const CovidMsg = () => {
  return (
    <CovidAlert
      banner
      message={
        <CovidMessage>
          <InfoCircleOutlined
            style={{ marginRight: "8px", fontSize: "14px" }}
          />
          A note to our community regarding COVID-19.{" "}
          <Link to="/covid-note">Learn More</Link>
        </CovidMessage>
      }
      type="info"
      closable
      showIcon={false}
    />
  );
};

  const PlayStoreButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    return (
      <Fragment>
        <HeroBottomBtn onClick={showModal}>
          Download App
        </HeroBottomBtn>
      <Modal title="Download App" centered open={isModalOpen} onOk={handleOk} onCancel={handleOk} footer={null}>
        <PlaystoreButtonContainer>
        <PlaystoreButton href="https://play.google.com/store/apps/details?id=com.prajekpro.custapp"><ArrowDownOutlined /> Download Cutsomer App</PlaystoreButton>
        <PlaystoreButton href="https://play.google.com/store/apps/details?id=com.prajekpro.profapp"><ArrowDownOutlined /> Download Professional App</PlaystoreButton>
        </PlaystoreButtonContainer>
      </Modal>
      </Fragment>
    )
  }

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible } = this.state;
    return (
      <>
        <CovidMsg />
        <MainHeader>
          <HeaderContainer style={{ height: "100%" }}>
            <Row gutter={16} style={{ height: "100%" }}>
              <Col xs={13} md={7} lg={5} style={{ height: "100%" }}>
                <LogoLink to="/">
                  <img src={siteLogo} alt="Logo" />
                </LogoLink>
              </Col>
              <Col xs={11} md={17} lg={19} style={{ height: "100%" }}>
                <TopMenu>
                  <MenuList>
                    <MenuItem to="/about-us">about us</MenuItem>
                  </MenuList>
                  <MenuList>
                    <MenuItem to="/contact-us">contact us</MenuItem>
                  </MenuList>
                  <MenuList>
                    <MenuItem to="/faqs">FAQ's</MenuItem>
                  </MenuList>
                  <MenuList>
                    <PlayStoreButton/>
                  </MenuList>
                </TopMenu>
                <MenuBtnContainer>
                  <MenuBtn onClick={this.showDrawer}>
                    <MenuOutlined />
                  </MenuBtn>
                </MenuBtnContainer>
              </Col>
            </Row>
          </HeaderContainer>
        </MainHeader>
        <MenuDrawer closable={false} onClose={this.onClose} visible={visible}>
          <CloseDrawerBtn type="link" onClick={this.onClose}>
            <CloseOutlined />
          </CloseDrawerBtn>
          <ClearFix />
          <MobileMenu>
            <MenuList>
              <MenuItem to="/about-us" onClick={this.onClose}>
                about us
              </MenuItem>
            </MenuList>
            <MenuList>
              <MenuItem to="/contact-us" onClick={this.onClose}>
                contact us
              </MenuItem>
            </MenuList>
            <MenuList>
              <MenuItem to="/faqs" onClick={this.onClose}>
                FAQ's
              </MenuItem>
            </MenuList>
            <MenuList>
              <PlayStoreButton />
            </MenuList>
          </MobileMenu>
        </MenuDrawer>
      </>
    );
  }
}

export default Header;
