import axios from 'axios';
import { API_ENDPOINT } from '../constants/api-constant';

class APIService {
    constructor() {
        this.axios = axios.create({
            baseURL: API_ENDPOINT
        });
    }


    post = (url, payload) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await this.axios.post(url, payload);
            resolve(response.data);
          } catch (err) {
            console.log("APIService : post error", err);
            reject(err);
          }
        });
    };
}

export default new APIService();
