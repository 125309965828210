import React, { Component, useState } from "react";
import { Row, Col } from "antd";
import proGifImg from "./images/PrajekPro.gif";
import femaleUser from "./images/mary.jpeg";
import maleUser from "./images/vishal.jpeg";
import Priyanka from "./images/priyanka.jpeg";
import Regina from "./images/regina.jpeg";
import Petronila from "./images/petronila.jpeg";
import Rohit from "./images/rohit.jpg";
import Piyush from "./images/male-user.png";
import { SectionContainer } from "../../globalStyle";
import {
  BannerWrapper,
  BannerTitle,
  WhoWeAreContainer,
  WhoWeAreTitle,
  WhoWeAreSubText,
  WhoWeAreInnerContent,
  HowItWorksContainer,
  HowItWorksTitle,
  HowItWorksText,
  PrajekProContainer,
  BehindPrajeckProTitle,
  TeamCard,
  TeamImg,
  CardBody,
  TeamMate,
  TeamDesc,
  TeamMateWrapper,
  Designation,
  BioBtn,
  BioModal
} from "./Aboutus.style";

const TeamData = [
  {
    userName: "mary-chavan",
    name: "Mary Navalta",
    desc: `Mary was born and raised in Manila, Philippines. Graduate of Bachelor of Science in International Tourism, major in Hospitality Management and a discipline in Entrepreneurship.
          Mary is the President and founder of Prajekpro-a tech company in the Philippines where they created a platform for Filipinos to showcase, advertise, and capitalize on their skills and knowledge.
          Mary is the President of Filipino-American Chamber of Commerce of utah and a member of The Federation of Philippine American Chambers of Commerce, Inc. (FPACC)
          Mary is the President  of Altavan Foods LLC, a food and beverage company and is the CEO of an e-commerce business.
          Mary was crowned as Miss World Elite Utah- Philippines. She was awarded as Best Talent where she showcased her singing prowess. She was also awarded as Best Costume when she wore a mixed Traditional costume to represent multi-racial families.
          Mary firmly believes in being good, doing good and inclusivity.
          Mary volunteers for the Red Cross and Volunteers of America. She is also a supporter of LGBTQIA+- nonprofit called Queens of the Castro. Queens of the Castro provides multi-year scholarships to LGBTQIA+- youth in San Francisco, CA.`,
    designatinon: "President"
  },
  // {
  //   userName: "vishal-chavan",
  //   name: "Vishal Chavan",
  //   desc:  `Vishal Chavan is the Corporate consultant of PrajekPro.
  //           Vishal is the Business Development Director of Filipino-American Chamber of Commerce of Utah.
  //           Vishal co-owns Meena, an exclusive 9-course dining experience that showcases Indian-Portuguese and Indian-French style cuisines.
  //           Vishal is the Executive chef of Altavan Foods LLC, a restaurant company in the USA.
  //           Vishal worked with Michelin-starred and James Beard’s awarded chefs. 
  //           Vishal has been in the culinary world for over 15 years and he has served high-profiled guests like Dwayne Wade, Gabriella Union, Justin Bieber, and more.`,
  //   designatinon: "Corporate Consultant"
  // },
  {
    
    userName: "regina-gacusan",
    name: "Regina Marie Gacusan",
    desc: `Regina Marie Gacusan is the Corporate Secretary of PrajekPro.
           Regina is currently working as Property and Administrative Manger in a Drive in Hotel and handle properties for lease in Bacoor Cavite.
           Regina also manage her own Bike Accessories and Repair Shop in her home town in Parañaque`,
    designatinon: "Secretary"
  },
  {
    userName: "petronila-navalta",
    name: "Petronila Navalta",
    desc: "Petronila Navalta is the Corporate Secretary of PrajekPro",
    designatinon: "Treasurer"
  }
];

class AboutUs extends Component {
  state = {};
  render() {
    const Banner = () => {
      return (
        <BannerWrapper>
          <BannerTitle>about us</BannerTitle>
        </BannerWrapper>
      );
    };

    const WhoWeAre = () => {
      return (
        <WhoWeAreContainer>
          <SectionContainer>
            <Row gutter={16}>
              <Col
                xs={{ span: 24, order: 2 }}
                md={{ span: 17, order: 1 }}
                lg={{ span: 12, order: 1 }}
              >
                <WhoWeAreInnerContent>
                  <WhoWeAreTitle>What is Prajekpro?</WhoWeAreTitle>
                  <WhoWeAreSubText>
                    Welcome to Prajekpro, your number one source for service
                    Pros in the Philippines. We're dedicated to giving you the
                    power to get all your projects done with the help of our
                    Pros. We know the feeling-we’ve been there, we’ve seen it,
                    we’ve experienced it.
                  </WhoWeAreSubText>
                  <WhoWeAreSubText>
                    Conveniency, reliability, and trustworthy. These three are
                    our main focus. <strong>Convenience</strong> Use the app
                    whenever you need help. <strong>Reliability</strong> Book
                    the appointment and be at peace that your project will get
                    done with the help of our Pros. <strong>Trustworthy</strong>{" "}
                    Know your pro before booking the appointment. Read the
                    reviews, and even send message to the pros.
                  </WhoWeAreSubText>
                  <WhoWeAreSubText>
                    Founded in 2020 by Mary Navalta-Chavan, Prajekpro was
                    created due to frustration. With little to no knowledge how
                    to fix the grounded house lights, and to make it worse,
                    there was no service search app that’s available in her
                    location, Mary knew that there’s a void that needs to be
                    filled. Mary teamed up with people she knows that will and
                    can make it happen, and above all, a team that shares the
                    same passion and dreams.
                  </WhoWeAreSubText>
                </WhoWeAreInnerContent>
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                md={{ span: 7, order: 2 }}
                style={{ margin: "0 auto" }}
              >
                <img src={proGifImg} style={{ width: "100%" }} />
              </Col>
            </Row>
          </SectionContainer>
        </WhoWeAreContainer>
      );
    };

    const HowItWorks = () => {
      return (
        <HowItWorksContainer>
          <SectionContainer>
            <HowItWorksTitle>Why Prajekpro?</HowItWorksTitle>
            <HowItWorksText>
              By using our app, you are helping individuals and companies to
              either launch and expand their businesses. This platform is a
              two-way street- you get your project done by booking the pros and
              in return the pros grow. If you have any questions or comments,
              please don't hesitate to contact us.
            </HowItWorksText>
          </SectionContainer>
        </HowItWorksContainer>
      );
    };

    const BehindPrajeckPro = () => {
      const [isModalVisible, setIsModalVisible] = useState(false);
      const [selectedUserName, setSelectedUser] = useState();
      const showModal = userName => {
        setSelectedUser(userName);
        setIsModalVisible(true);
      };
      const handleOk = () => {
        setIsModalVisible(false);
      };
      const handleCancel = () => {
        setIsModalVisible(false);
      };

      return (
        <>
          <PrajekProContainer>
            <SectionContainer>
              <BehindPrajeckProTitle>
                Behind the Prajekpro
              </BehindPrajeckProTitle>
              <Row gutter={25} className="profile-section">
                {TeamData.map(teamBio => (
                  <Col xs={24} sm={12} lg={6}>
                    <TeamCard key="1">
                      <CardBody>
                        <TeamMate>{teamBio.name}</TeamMate>
                        <Designation>{teamBio.designatinon}</Designation>
                        <BioBtn
                          type="button"
                          onClick={userName => showModal(teamBio.userName)}
                        >
                          Bio
                        </BioBtn>
                      </CardBody>
                    </TeamCard>
                  </Col>
                ))}
              </Row>
            </SectionContainer>
          </PrajekProContainer>
          <BioModal
            title={TeamData.map(teamBio =>
              teamBio.userName == selectedUserName ? <>{teamBio.name}</> : ""
            )}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            centered={true}
          >
            {TeamData.map(teamBio =>
              teamBio.userName == selectedUserName ? (
                <TeamDesc>{teamBio.desc}</TeamDesc>
              ) : (
                ""
              )
            )}
          </BioModal>
        </>
      );
    };
    return (
      <>
        <Banner />
        <WhoWeAre />
        <HowItWorks />
        <BehindPrajeckPro />
      </>
    );
  }
}

export default AboutUs;
